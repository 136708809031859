<template>
  <CardLayout title="Incident Information" :loading="loading">
    <dl
      v-if="incident"
      class="property-list my-4"
      :class="{ 'two-columns': !singleColumn }"
    >
      <dt>Incident ID</dt>
      <dd>{{ incident.shortId }}</dd>
      <dt>Facility</dt>
      <dd>{{ incident.facilityName }}</dd>
      <dt>Incident Type</dt>
      <dd>
        <IconIncidentType :incident="incident" />
        {{ incident.typeName }}
      </dd>
      <dt>Smart Unit/Zone</dt>
      <dd>{{ incident.zoneName }}</dd>
      <dt>Priority</dt>
      <dd>
        <SeverityChip :severity="titleCase(incident.priority)" />
      </dd>
      <dt>Renter Name</dt>
      <dd>
        <UserIcon
          v-if="incident.hasDisabledLienResponder"
          left
          :responder="incident.responder"
        />
        {{ incident.responder.name }}
      </dd>
      <dt>Incident Status</dt>
      <dd>
        <IconIncidentStatus small :incident="incident" />
        {{ incident.statusName }}
      </dd>
      <dt>Incident Disposition</dt>
      <dd>{{ incident.incidentDisposition }}</dd>
      <dt>Duration</dt>
      <dd>{{ incident.duration }}</dd>

      <dt>Incident Started On</dt>
      <dd>{{ incident.startedAt }}</dd>

      <dt>Incident Due Date</dt>
      <dd>{{ incident.dueAt }}</dd>
    </dl>

    <template v-if="!loading && !incident.needsSurvey" #actions>
      <v-btn color="grey lighten-2" @click="$router.go(-1)"> Done </v-btn>
    </template>
  </CardLayout>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import IconIncidentStatus from "@atoms/IconIncidentStatus.vue";
import IconIncidentType from "@atoms/IconIncidentType.vue";
import UserIcon from "../renters/UserIcon.vue";
import useIncident from "./useIncident";
import { titleCase } from "@tod-ui/helpers/strings";
import SeverityChip from "@tod-ui/components/SeverityChip.vue";

export default {
  name: "CardIncidentInfo",
  components: {
    CardLayout,
    IconIncidentStatus,
    IconIncidentType,
    UserIcon,
    SeverityChip,
  },
  props: {
    singleColumn: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { incident, loading } = useIncident();
    return { incident, loading };
  },
  methods: {
    titleCase,
  },
};
</script>

<style lang="scss" scoped>
.property-list {
  dd {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &.two-columns {
    grid-template-columns: repeat(2, max-content auto);
  }
}
</style>

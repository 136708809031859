<template>
  <!-- use div to allow classes like "mr/pr" to be applied -->
  <div>
    <v-menu
      v-model="showFilter"
      bottom
      :close-on-click="false"
      :close-on-content-click="false"
      left
      max-width="300px"
      min-width="300px"
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-btn outlined small class="action-btn" v-bind="attrs" v-on="on">
          <v-icon left small>mdi-filter</v-icon>
          Filter
          <template v-if="filterCount > 0">
            <v-divider vertical class="ml-2 mr-2" />
            <span class="primary--text">
              {{ filterCount }}
            </span>
          </template>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar>
          <v-btn small outlined color="grey" @click="clear">Clear</v-btn>
          <v-spacer />
          <v-toolbar-title class="subtitle-1 ml-0 mr-0">Filter</v-toolbar-title>
          <v-spacer />
          <v-btn small depressed color="primary" @click="accept">Done</v-btn>
        </v-toolbar>

        <v-expansion-panels accordion multiple>
          <v-expansion-panel>
            <v-expansion-panel-header> Type </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-checkbox
                v-for="type in incidentTypes"
                :key="type"
                v-model="filter.types"
                dense
                :label="titleCase(type)"
                :value="type"
              />
              <v-checkbox
                v-model="filter.afterHours"
                dense
                value="after-hours"
                label="After Hours Activity"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header> Status </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-checkbox
                v-for="status in statuses"
                :key="status.value"
                v-model="filter.statuses"
                dense
                :label="titleCase(status.text)"
                :value="status.value"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              Access Code Status
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-checkbox
                v-for="status in accessStatuses"
                :key="status"
                v-model="filter.accessStatuses"
                dense
                :label="accessStatus(status)"
                :value="status"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header> Location </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-checkbox
                v-for="subtype in zoneSubtypes"
                :key="subtype.value"
                v-model="filter.locations"
                dense
                :label="titleCase(subtype.text)"
                :value="subtype.value"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header> Priority </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-checkbox
                v-for="status in severityStatus"
                :key="status.value"
                v-model="filter.severityStatus"
                dense
                :label="titleCase(status.text)"
                :value="status.value"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header> Overdue </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-checkbox
                v-for="status in overdueStatus"
                :key="status.value"
                v-model="filter.overdueStatus"
                dense
                :label="titleCase(status.text)"
                :value="status.value"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { titleCase } from "@tod-ui/helpers/strings";
import { accessStatus } from "../common/helpers/incident";

const EMPTY_FILTER = {
  locations: [], // unit || zone
  statuses: [], // status + resolution macros
  types: [], // incident type
  afterHours: undefined, // incident sub type(ex. After hours activity)
  accessStatuses: [], // access code status
  severityStatus: [], // severity
  overdueStatus: [], // overdue
};

export default {
  name: "IncidentFilter",
  props: {
    autoFilter: {
      type: Object,
      default: () => ({
        status: "",
        dateRange: { after: null, before: null },
      }),
    },
  },
  data: () => ({
    filter: { ...EMPTY_FILTER },
    showFilter: false,
    incidentTypes: [
      "motion",
      "entry",
      "service_request",
      "humidity",
      "temperature",
    ],
    zoneSubtypes: [
      { value: "unit", text: "Smart Unit" },
      { value: "zone", text: "Smart Zone" },
    ],
    statuses: [
      { value: "authorized", text: "Authorized" },
      { value: "unauthorized", text: "Unauthorized" },
      { value: "unacknowledged", text: "Unacknowledged" },
    ],
    accessStatuses: [
      "presence_confirmed",
      "unconfirmed_access",
      "bypassed_access",
    ],
    severityStatus: [
      { value: "critical", text: "Critical" },
      { value: "severe", text: "Severe" },
      { value: "substantial", text: "Substantial" },
      { value: "moderate", text: "Moderate" },
      { value: "low", text: "Low" },
    ],
    overdueStatus: [{ value: "overdue", text: "Overdue" }],
  }),

  computed: {
    filterCount() {
      let count = 0;

      for (let k in this.filter) {
        const value = this.filter[k];

        if (value !== null && value !== undefined) {
          if (typeof value === "boolean") {
            count += 1;
          } else if (value.length > 0) {
            count += 1;
          }
        }
      }

      return count;
    },
  },
  watch: {
    "autoFilter.status": {
      handler(newStatus) {
        if (newStatus === "overdue") {
          this.filter.overdueStatus = ["overdue"];
          this.$emit("apply", { ...this.filter });
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.autoFilter.status === "overdue") {
      this.filter.overdueStatus = ["overdue"];
      this.$emit("apply", { ...this.filter });
    }
  },
  methods: {
    accept() {
      this.$emit("apply", { ...this.filter });
      this.showFilter = false;
    },
    clear() {
      this.filter = { ...EMPTY_FILTER };
    },
    titleCase,
    accessStatus,
  },
};
</script>

<style lang="scss" scoped>
.v-input {
  margin-top: 0px;
}
.v-input--dense {
  margin-bottom: -18px;
}
.active {
  background: lightblue;
}
</style>

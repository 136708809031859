<template>
  <LayoutPage title="Notifications">
    <DialogViewTask
      v-if="isOpenViewTask"
      v-model="isOpenViewTask"
      :task-id="selectedTaskId"
    />
    <v-tabs v-model="tab">
      <v-tab
        v-for="user in notificationUsers"
        :key="user.id"
        :title="`Username: ${user.username}; Total unread: ${
          totalUnreadUserNotifications[user.id]
        }`"
      >
        {{ user.firstName }} {{ user.lastName }}
        <span v-if="currentUserId == user.id" class="pl-2">(me)</span>
        <span v-if="!loading"
          >:
          {{
            currentUserId == user.id
              ? currentUserUnreadTotal
              : totalUnreadUserNotifications[user.id]
          }}</span
        >
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="user in notificationUsers" :key="user.id">
        <v-data-table
          :headers="[
            {
              text: 'Notification',
              value: 'summary',
              width: '50%',
            },
            {
              text: 'Created',
              value: 'insertedAt',
              class: 'text-no-wrap',
            },
            {
              text: 'Actions',
              value: 'actions',
              sortable: false,
              filterable: false,
            },
          ]"
          :items="
            user.id === currentUserId
              ? notifications
              : userNotifications[user.id]
          "
          :search="search"
          :sort-by="['insertedAt']"
          :sort-desc="[true]"
          :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
          :loading="loading"
        >
          <template #body="{ items }">
            <tbody v-if="loading">
              <tr>
                <td colspan="3" class="text-center grey--text text--darken-2">
                  Loading user notifications
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="items.length === 0">
              <tr>
                <td colspan="3" class="text-center grey--text text--darken-2">
                  {{
                    user.id === currentUserId
                      ? "You are"
                      : `${user.firstName} is`
                  }}
                  all caught up! No notifications found.
                </td>
              </tr>
            </tbody>
            <tbody is="transition-group" v-else name="list">
              <tr
                v-for="item in items"
                :key="item.id"
                class="item-row"
                :class="`notification-${item.id}`"
                @click="toggleNotification(item)"
              >
                <td>
                  <div class="notification-title">
                    <div class="d-flex align-start">
                      <v-icon
                        class="circle-icon"
                        left
                        small
                        :color="severityColor(item.severity)"
                      >
                        mdi-circle
                      </v-icon>
                      <span :class="item.status">
                        {{ item.title }}
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="selectedNotification === item.id"
                    class="notification-text"
                  >
                    {{ item.body }}
                  </div>
                </td>

                <td class="action-cell">
                  <DialogNotificationHistory
                    :notification-id="item.id"
                    class="mr-2"
                  />
                  <span :class="item.status">
                    {{
                      facilityFormatDateTime4Humans(
                        item.insertedAt,
                        false,
                        false
                      )
                    }}
                  </span>
                </td>

                <td class="action-cell">
                  <div v-if="item.name === 'vacant_smart_unit_motion_incident'">
                    <v-menu v-model="menuStates[item.id]" offset-y>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          small
                          class="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="
                            toggleMenu(
                              item.id,
                              getFilteredEntityId(item.triggeringEntities)
                            )
                          "
                        >
                          Select Reason
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item @click="selectOption('unit_cleaning')">
                          <v-list-item-title>Unit Cleaning</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="selectOption('suspicious_motion')">
                          <v-list-item-title
                            >Suspicious Motion</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item
                          @click="selectOption('prospective_tenant')"
                        >
                          <v-list-item-title
                            >Prospective Tenant</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item
                          @click.stop.prevent="openOtherReasonDialog"
                        >
                          <v-list-item-title>Other Reason</v-list-item-title>
                        </v-list-item>
                      </v-list>
                      <v-dialog v-model="dialog" max-width="700px">
                        <v-card>
                          <v-card-title>
                            <span class="headline"
                              >Specify other reasons there was a motion in a
                              unit</span
                            >
                            <v-spacer />
                            <v-btn icon @click="closeDialog">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-card-title>
                          <div class="pa-4">
                            <AlertsAnimated :alerts="localAlerts" />
                          </div>
                          <v-card-text>
                            <div class="mt-4">
                              <span class="subtitle-1"
                                >Authorization Status</span
                              >
                              <v-select
                                v-model="authorizationStatus"
                                :items="authorizationOptions"
                                item-text="text"
                                item-value="value"
                                label="Select Authorization Status"
                                outlined
                                class="mt-2"
                              />
                            </div>

                            <v-textarea
                              v-model="otherReason"
                              label="Enter your reason here"
                              rows="4"
                              outlined
                              class="mt-4"
                            />
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer />
                            <v-btn color="primary" @click="addOtherReason"
                              >Add Reason</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-menu>
                  </div>

                  <div v-if="item.name == 'task_assigned'">
                    <v-btn
                      small
                      class="primary"
                      @click="openTaskDialog(item.triggeringEntities)"
                    >
                      View Task
                    </v-btn>
                  </div>

                  <NotificationActionButtons
                    :notification="item"
                    :is-for-current-user="user.id === currentUserId"
                    @redirect="redirect"
                    @dismiss="dismiss"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import NotificationActionButtons from "./NotificationActionButtons.vue";
import DialogNotificationHistory from "./DialogNotificationHistory.vue";

import { severityColor, templateLabel, viewNotification } from "./helper";
import { titleCase } from "@tod-ui/helpers/strings";
import useFacility from "@components/authentication/useFacility";
import useUser from "@components/authentication/useUser";
import useNotifications from "./useNotifications";
import { ref, computed } from "vue";
import { useRoute } from "vue-router/composables";
import useIncident from "@components/incident/useIncident";
import useAlerts from "@tod-ui/composables/useAlerts";
import DialogViewTask from "../manage_tasks/DialogViewTask.vue";
import AlertsAnimated from "@tod-ui/components/AlertsAnimated.vue";

export default {
  name: "PageNotifications",
  components: {
    LayoutPage,
    DialogViewTask,
    NotificationActionButtons,
    DialogNotificationHistory,
    AlertsAnimated,
  },

  setup() {
    const { fetchIncident, submitIncidentSurvey } = useIncident();
    const { localAlerts, addAlert, clearAllAlerts, addLocalAlert } =
      useAlerts();

    const { facilityFormatDateTime4Humans, facilityUsers, fetchFacilityUsers } =
      useFacility();
    const {
      notifications,
      unreadNotifications,
      userNotifications,
      totalUnreadUserNotifications,
      loading,
      fetchNotifications,
      markNotificationRead,
      redirectAndMarkAsRead,
      dismissNotification,
    } = useNotifications();

    const currentUserUnreadTotal = computed(
      () => unreadNotifications.value.length
    );
    const { currentUser } = useUser();
    const currentUserId = computed(() => currentUser.value?.id);
    const notificationUsers = computed(() => [
      currentUser.value,
      ...facilityUsers.value.filter((u) => u.id !== currentUserId.value),
    ]);
    const selectedTaskId = ref({});

    const $route = useRoute();
    const isOpenViewTask = ref(false);

    const tab = ref();
    const search = ref("");
    const selectedNotification = ref($route?.params?.id);
    const survey = ref({
      vacantUnitSurveyReason: null,
      authorizationStatus: null,
    });

    return {
      facilityFormatDateTime4Humans,
      notifications,
      currentUserUnreadTotal,
      userNotifications,
      totalUnreadUserNotifications,
      loading,
      tab,
      search,
      notificationUsers,
      currentUserId,
      isOpenViewTask,
      selectedTaskId,
      selectedNotification,
      fetchNotifications,
      markNotificationRead,
      fetchFacilityUsers,
      redirectAndMarkAsRead,
      dismissNotification,
      survey,
      fetchIncident,
      submitIncidentSurvey,
      addAlert,
      addLocalAlert,
      localAlerts,
    };
  },
  data() {
    return {
      menuStates: {},
      dialog: false,
      otherReason: "",
      authorizationStatus: null,
      authorizationOptions: [
        { text: "Authorized", value: "authorized" },
        { text: "Unauthorized", value: "un_authorized" },
      ],
    };
  },
  watch: {
    notificationUsers: {
      handler(users) {
        users.forEach((u) => {
          if (u.id !== this.currentUserId)
            this.fetchNotifications(this.$apollo, u.id);
        });
      },
      immediate: true,
    },
    selectedNotification(notificationId) {
      if (notificationId) {
        this.$vuetify.goTo(`.notification-${notificationId}`, {
          offset: 60,
        });
      }
    },
  },
  mounted() {
    this.fetchFacilityUsers(this.$apollo);
  },
  methods: {
    getFilteredEntityId(entities) {
      const filteredEntity = entities
        .map((entity) => JSON.parse(entity))
        .find(
          (entity) => entity.type === "motion" || entity.type === "incident"
        );
      return filteredEntity ? filteredEntity.id : null;
    },
    async resolveIncident() {
      try {
        // Call the submitIncidentSurvey function with the updated survey
        const response = await this.submitIncidentSurvey(
          this.$apollo,
          this.survey
        );

        if (response?.status === "success") {
          this.addAlert({
            type: "success",
            message: "Incident Resolved with a completed survey",
            timeout: 15,
          });
          this.fetchNotifications(this.$apollo, this.currentUserId);
        }
      } catch (error) {
        this.addAlert({
          type: "error",
          message: "Error occurred while saving incident survey.",
          timeout: 10,
        });
      }
    },

    clear() {
      this.survey = { vacantUnitSurveyReason: null, authorizationStatus: null };
      this.$refs.validator.reset();
    },
    toggleMenu(itemId, incidentId) {
      this.fetchIncident(this.$apollo, incidentId);
      this.$set(this.menuStates, itemId, !this.menuStates[itemId]);
    },

    selectOption(reason) {
      this.survey.vacantUnitSurveyReason = reason;

      if (reason === "unit_cleaning" || reason === "prospective_tenant") {
        this.authorizationStatus = "authorized";
      } else if (reason === "suspicious_motion") {
        this.authorizationStatus = "un_authorized";
      }
      this.survey.authorizationStatus = this.authorizationStatus;

      this.resolveIncident();
      this.menuStates = {};
    },
    openOtherReasonDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.otherReason = "";
    },
    addOtherReason() {
      if (!this.otherReason.trim()) {
        this.addLocalAlert({
          type: "error",
          message: "Please enter a valid reason before proceeding.",
          timeout: 10,
        });
        return;
      }
      this.selectOption(this.otherReason);
      this.closeDialog();
    },
    openTaskDialog(triggeringEntities) {
      const firstEntity = JSON.parse(triggeringEntities[0]);
      this.isOpenViewTask = true;
      return (this.selectedTaskId = firstEntity.id);
    },
    async toggleNotification(notification) {
      if (notification.id === this.selectedNotification) {
        this.selectedNotification = null;
        this.$router.replace({ name: "Notifications" });
      } else {
        this.selectedNotification = notification.id;
        this.markNotificationRead(this.$apollo, notification);
        this.$router.replace(viewNotification(notification));
      }
    },
    async redirect(notification) {
      await this.redirectAndMarkAsRead(this.$apollo, notification);
    },
    async dismiss(notification) {
      await this.dismissNotification(this.$apollo, notification);
    },
    severityColor,
    templateLabel,
    titleCase,
  },
};
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(50%);
}

.list-move {
  transition: transform 0.5s;
}

.item-row {
  display: table-row;
}

.notification-link {
  text-decoration: none;
  color: currentColor;
}

.published {
  font-weight: bold;
}

.action-cell {
  vertical-align: top;
  padding-top: 10px !important;
}

.circle-icon {
  line-height: 30px;
}

.notification-title {
  line-height: 30px;
  cursor: pointer;
}

.notification-text {
  min-height: 48px;
  padding-left: 2em;
  color: gray;
}
</style>

<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-select
          v-model="selectedSensor"
          :items="formattedSensorTypes"
          item-text="text"
          item-value="value"
          label="Sensor"
          outlined
          dense
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="selectedPriority"
          :items="formattedPriorityLevels"
          item-text="text"
          item-value="value"
          label="Priority"
          outlined
          dense
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          outlined
          dense
        />
      </v-col>
      <v-col cols="3">
        <div v-if="showActions">
          <v-btn
            color="primary"
            class="mr-2"
            :loading="saving"
            @click="handleSave"
          >
            Save
          </v-btn>
          <v-btn color="grey" :disabled="saving" @click="handleCancel">
            Cancel
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <DialogLayout
      v-model="isOpenDialog"
      title="Authorization Status"
      width="500px"
    >
      <template #default>
        <div class="d-flex justify-content-center">
          <p v-if="authorizationResponse" class="text-subtitle-1">
            <span v-if="authorizationResponse === 'authorized'">
              <span class="">Primary or Secondary said</span>
              <span class="font-weight-bold mx-2 border">Yes</span>
              <span>:</span>
              <span class="mx-3">Overall response is</span>
              <span
                class="border m-2 font-weight-bold"
                style="color: green; border: 1px solid #000; padding: 3px"
                >Yes</span
              >
            </span>
            <span v-else-if="authorizationResponse === 'unauthorized'">
              <span>Primary or Secondary said</span>
              <span class="font-weight-bold border p-1 mx-2">No</span>
              <span>:</span>
              <span class="mx-3">Overall response is</span>
              <span
                class="font-weight-bold border p-1"
                style="color: red; border: 1px solid #000; padding: 3px"
                >No</span
              >
            </span>
            <span v-else>
              <span>Primary and Secondary said</span>
              <span
                class="font-weight-bold border mx-2"
                style="color: red; border: 1px solid #000; padding: 3px"
                >did not reply back</span
              >
            </span>
          </p>
        </div>
      </template>
    </DialogLayout>

    <DialogLayout
      v-model="isNotificationModalOpen"
      title="Notification Settings"
      width="900px"
    >
      <NotificationModal
        :settings="selectedNotificationSettings"
        :read-only="true"
      />
    </DialogLayout>

    <v-skeleton-loader
      :loading="$apollo.queries.escalationMatrix.loading"
      type="table-thead, table-row-divider@6"
    >
      <v-data-table
        :headers="headers"
        :items="sensorData"
        :search="search"
        :custom-filter="customFilter"
        class="elevation-1"
        :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
      >
        <template #item.shortCode="{ item }">
          <div>{{ toUpperCase(item.shortCode) }}</div>
        </template>
        <template #item.incidentDescription="{ item }">
          <div
            style="width: 340px; white-space: normal; word-break: break-word"
          >
            {{ item.incidentDescription }}
          </div>
        </template>

        <template #item.detectedThreshold="{ item }">
          <div v-if="item.hasVAD">
            <span v-if="item.sensorType == 'Motion'" class="text-caption"
              >VAD:</span
            >
            <span v-else> </span>
            <div>
              <v-select
                v-if="item.thresholdSign !== null"
                v-model="item.thresholdSign"
                :items="thresholdSigns"
                class="d-inline-block"
                style="width: 70px"
                dense
                outlined
                hide-details
                :disabled="fieldDisabled"
                @change="handleThresholdSignChange(item)"
              />
              <v-text-field
                :value="formatVadValueForDisplay(item)"
                class="d-inline-block mx-2"
                style="width: 60px"
                dense
                outlined
                hide-details
                :disabled="fieldDisabled"
                @input="handleVadValueChange($event, item)"
              />
              <v-select
                v-model="item.timeUnit"
                :items="timeUnits"
                class="d-inline-block"
                style="width: 100px"
                dense
                outlined
                hide-details
                :disabled="fieldDisabled"
              />
            </div>
          </div>
          <div v-else>
            <div
              v-if="item.sensorType === 'Xp'"
              class="d-flex justify-content-center"
            >
              <v-btn
                text
                color="blue"
                class="px-0"
                @click="openAuthorizationDialog(item)"
              >
                Conditional Logic
              </v-btn>
            </div>
            <div v-else>
              <span>State Change Detected</span>
            </div>
          </div>
        </template>

        <template #item.priorityLevel="{ item }">
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-chip
                class="priority-chip px-4"
                :color="getColorBySeverity(item.priority)"
                v-bind="attrs"
                medium
                dark
                v-on="on"
              >
                {{ formatFieldName(item.priority) }}
                <v-icon small right class="ml-1" d>mdi-chevron-down</v-icon>
              </v-chip>
            </template>
            <v-list dense class="py-0">
              <v-list-item
                v-for="priority in formattedPriorityLevels.filter(
                  (p) => p.value !== 'all'
                )"
                :key="priority.value"
                class="priority-item"
                :disabled="fieldDisabled"
                @click="handlePriorityChange(item, priority.value)"
              >
                <v-list-item-title>
                  {{ priority.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template #item.notificationPreference="{ item }">
          <v-btn
            outlined
            color="primary"
            @click="openNotificationSettings(item)"
          >
            View
          </v-btn>
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </div>
</template>

<script>
import { formatFieldName } from "@components/manage_tasks/helper";
import {
  GET_OVERRIDDEN_ORGANIZATION_FEATURE,
  UPDATE_ORGANIZATION_FEATURE,
} from "./graphql";
import { getColorBySeverity } from "@components/dashboard/helper";
import DialogLayout from "@layout/DialogLayout.vue";
import NotificationModal from "./NotificationModal.vue";
import useAlerts from "@tod-ui/composables/useAlerts";
import useUser from "../authentication/useUser";
import { titleCase, toUpperCase, toLowerCase } from "@tod-ui/helpers/strings";

export default {
  name: "CardIncidentEscalationMatrix",
  components: {
    DialogLayout,
    NotificationModal,
  },

  props: {
    initialSensor: {
      type: String,
      default: null,
    },
    initialPriority: {
      type: String,
      default: null,
    },
    priorityMatrix: {
      type: Array,
      default: () => [],
    },
    featureId: {
      type: String,
      default: null,
    },
    fieldDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { defaultOrganization } = useUser();
    const { addAlert } = useAlerts();
    return {
      addAlert,
      defaultOrganization,
    };
  },
  data() {
    return {
      selectedSensor: "motion",
      selectedPriority: "all",
      timeUnits: ["mins", "secs"],
      selectedSensorItem: null,
      saving: false,
      headers: [
        { text: "Sensor Type", value: "sensorType" },
        { text: "Incident Code", value: "shortCode" },
        { text: "Incident Description", value: "incidentDescription" },
        { text: "Schedule", value: "schedule" },
        { text: "Detected Threshold Variables", value: "detectedThreshold" },
        { text: "Priority Level", value: "priorityLevel" },
        { text: "Notification Preference", value: "notificationPreference" },
      ],
      sensorTypes: ["motion", "door"],
      priorityLevels: [
        "all",
        "critical",
        "substantial",
        "severe",
        "moderate",
        "low",
      ],
      thresholdSigns: [
        { text: ">", value: "gt" },
        { text: "<", value: "lt" },
      ],
      escalationMatrix: null,
      originalData: null,
      hasChanges: false,
      isOpenDialog: false,
      authorizationResponse: null,
      isNotificationModalOpen: false,
      selectedNotificationSettings: [],
      search: "",
    };
  },

  computed: {
    sensorData: {
      get() {
        if (!this.escalationMatrix) return [];
        let data = [];
        const formatConfigData = (config, hasConditionalLogic) => ({
          id: config.id,
          sensorType: formatFieldName(config.sensorType),
          shortCode: config.shortCode,
          incidentDescription: config.description,
          schedule: formatFieldName(config.schedule),
          hasVAD: config.threshold !== null,
          thresholdSign: config.thresholdSign || null,
          vadValue: config.threshold?.toString() || "",
          timeUnit: "mins",
          priority: config.priority,
          hasConditionalLogic: hasConditionalLogic,
          authorization: hasConditionalLogic ? config.authorization : null,
          _original: { ...config },
        });

        if (toLowerCase(this.selectedSensor) === "motion") {
          data = this.escalationMatrix.motionSensorConfigs.map((config) =>
            formatConfigData(config, config.authorization != null)
          );
        }

        if (toLowerCase(this.selectedSensor) === "door") {
          data = this.escalationMatrix.doorSensorConfigs.map((config) =>
            formatConfigData(config, false)
          );
        }

        if (toLowerCase(this.selectedPriority) !== "all") {
          data = data.filter(
            (item) =>
              toLowerCase(item.priority) === toLowerCase(this.selectedPriority)
          );
        }

        return data;
      },
    },
    formattedSensorTypes() {
      return this.sensorTypes.map((type) => ({
        text: this.titleCase(type),
        value: toLowerCase(type),
      }));
    },
    formattedPriorityLevels() {
      return this.priorityLevels.map((level) => ({
        text: this.titleCase(level),
        value: toLowerCase(level),
      }));
    },

    showActions() {
      return this.hasChanges;
    },
    isLoading() {
      return this.$apollo.queries.escalationMatrix.loading;
    },
  },
  apollo: {
    escalationMatrix: {
      query: GET_OVERRIDDEN_ORGANIZATION_FEATURE,
      variables() {
        return {
          featureName: "incident_escalation_matrix",
          organizationId: this.defaultOrganization.id,
        };
      },
      update(data) {
        if (data?.overridenOrganizationFeature?.config) {
          const config = {
            motionSensorConfigs:
              data.overridenOrganizationFeature.config.motionSensorConfigs ||
              [],
            doorSensorConfigs:
              data.overridenOrganizationFeature.config.doorSensorConfigs || [],
          };
          this.originalData = JSON.parse(JSON.stringify(config));
          this.hasChanges = false;
          return config;
        }
        return null;
      },
    },
  },

  watch: {
    initialSensor: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.selectedSensor = newVal === "door" ? "door" : "motion";
        }
      },
    },
    initialPriority: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.selectedPriority = newVal;
        }
      },
    },
  },

  methods: {
    customFilter(value, search, item) {
      if (!search) return true;

      const searchTerm = toLowerCase(search.toString());
      const searchableFields = [
        item.shortCode,
        item.incidentDescription,
        item.sensorType,
        item.schedule,
        item.priority,
        item.vadValue?.toString(),
      ];

      return searchableFields.some((field) =>
        toLowerCase(field).includes(searchTerm)
      );
    },
    openAuthorizationDialog(item) {
      this.authorizationResponse = item.authorization;
      this.isOpenDialog = true;
    },

    openNotificationSettings(item) {
      const prioritySettings = this.priorityMatrix?.find(
        (p) => p.name === toLowerCase(item.priority)
      );

      if (prioritySettings) {
        const incidentSetting = prioritySettings.incidentSettings?.find(
          (setting) =>
            toLowerCase(setting.type) === toLowerCase(this.selectedSensor)
        );

        if (
          incidentSetting?.notificationSettings?.managerNotificationSettings
        ) {
          this.selectedNotificationSettings =
            incidentSetting.notificationSettings.managerNotificationSettings;
          this.isNotificationModalOpen = true;
        }
      }
    },
    async handleSave() {
      this.saving = true;
      try {
        const removeTypename = (obj) => {
          const newObj = { ...obj };
          delete newObj.__typename;
          return newObj;
        };

        const updatedConfig = {
          motionSensorConfigs: this.originalData.motionSensorConfigs,
          doorSensorConfigs: this.originalData.doorSensorConfigs,
        };

        const modifiedItems = this.sensorData.filter((item) => {
          const original = item._original;
          const vadValueChanged =
            item.vadValue !== original.threshold?.toString();
          const thresholdSignChanged =
            item.thresholdSign !== original.thresholdSign;
          const priorityChanged = item.priority !== original.priority;

          return vadValueChanged || priorityChanged || thresholdSignChanged;
        });

        if (this.selectedSensor === "motion") {
          updatedConfig.motionSensorConfigs =
            updatedConfig.motionSensorConfigs.map((config) => {
              const modifiedItem = modifiedItems.find(
                (item) =>
                  item._original.description === config.description &&
                  item._original.schedule === config.schedule &&
                  item._original.sensorType === config.sensorType
              );

              if (modifiedItem) {
                return {
                  ...config,
                  priority: modifiedItem.priority,
                  threshold: parseInt(modifiedItem.vadValue),
                  thresholdSign: modifiedItem.thresholdSign,
                };
              }
              return config;
            });
        } else {
          updatedConfig.doorSensorConfigs = updatedConfig.doorSensorConfigs.map(
            (config) => {
              const modifiedItem = modifiedItems.find(
                (item) =>
                  item._original.description === config.description &&
                  item._original.schedule === config.schedule &&
                  item._original.sensorType === config.sensorType
              );

              if (modifiedItem) {
                return {
                  ...config,
                  priority: modifiedItem.priority,
                  threshold: parseInt(modifiedItem.vadValue),
                };
              }
              return config;
            }
          );
        }

        updatedConfig.motionSensorConfigs =
          updatedConfig.motionSensorConfigs.map(removeTypename);
        updatedConfig.doorSensorConfigs =
          updatedConfig.doorSensorConfigs.map(removeTypename);

        await this.$apollo.mutate({
          mutation: UPDATE_ORGANIZATION_FEATURE,
          variables: {
            id: this.featureId,
            organizationId: this.defaultOrganization.id,
            config: {
              incidentEscalationMatrix: updatedConfig,
            },
          },
        });
        this.selectedPriority = "all";

        this.$nextTick(() => {
          this.loading = true;
        });

        await this.$apollo.queries.escalationMatrix.refetch();

        this.$emit("save-success");
        this.addAlert({
          type: "success",
          message: "Incident escalation settings saved successfully",
          timeout: 5,
        });

        this.hasChanges = false;
      } catch (error) {
        this.$emit("save-error", error);
        this.addAlert({
          type: "error",
          message: "Error occurred while saving escalation settings.",
          timeout: 5,
        });
      } finally {
        this.saving = false;
        this.$nextTick(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
      }
    },

    checkForChanges(currentData) {
      if (!currentData || !this.originalData) {
        this.hasChanges = false;
        return;
      }

      const hasChanges = currentData.some((item) => {
        const original = item._original;
        return (
          item.vadValue !== original.threshold?.toString() ||
          item.priority !== original.priority ||
          item.thresholdSign !== original.thresholdSign
        );
      });

      this.hasChanges = hasChanges;
    },

    handleVadValueChange(value, item) {
      if (item.timeUnit === "mins") {
        item.vadValue = (parseInt(value) * 60).toString();
      } else {
        item.vadValue = value;
      }
      this.checkForChanges(this.sensorData);
    },

    handleTimeUnitChange(newUnit, item) {
      if (newUnit === "mins" && item.timeUnit === "secs") {
        item.vadValue = (parseInt(item.vadValue) / 60).toString();
      } else if (newUnit === "secs" && item.timeUnit === "mins") {
        item.vadValue = (parseInt(item.vadValue) * 60).toString();
      }
      item.timeUnit = newUnit;
      this.checkForChanges(this.sensorData);
    },

    formatVadValueForDisplay(item) {
      if (!item.vadValue) return "";
      return item.timeUnit === "secs"
        ? item.vadValue
        : (parseInt(item.vadValue) / 60).toString();
    },

    handleCancel() {
      if (this.originalData) {
        this.escalationMatrix = JSON.parse(JSON.stringify(this.originalData));
        this.hasChanges = false;
      }
    },
    handleThresholdSignChange(item) {
      this.checkForChanges(this.sensorData);
    },

    handlePriorityChange(item, newPriority) {
      item.priority = newPriority;
      this.checkForChanges(this.sensorData);
    },
    getColorBySeverity,
    formatFieldName,
    titleCase,
    toLowerCase,
    toUpperCase,
  },
};
</script>

<style scoped>
.v-data-table ::v-deep th {
  background-color: #f5f5f5 !important;
}
</style>

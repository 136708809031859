import gql from "graphql-tag";

export const CREATE_ORGANIZATION_FEATURE = gql`
  mutation (
    $organizationId: ID!
    $config: InputFeatureConfig!
    $disabled: Boolean!
    $featureId: ID!
  ) {
    createOrganizationFeature(
      featureId: $featureId
      organizationId: $organizationId
      config: $config
      disabled: $disabled
    ) {
      id
      disabled
      config {
        ... on IncidentPriorityMatrix {
          incidentPriorityMatrix {
            name
            level
            commonSettings {
              createAutoTask
              approvalRequired
              incidentOpenIn
              incidentProgressIn
              incidentResolvedIn
              incidentReviewedIn
              inspectionRequired
            }
            incidentSettings {
              taskAssignmentRole
              assigneeForApprovalRole
              type
              notificationSettings {
                managerNotificationSettings {
                  managerRole
                  email
                  robocall
                  sms
                  slack
                  teams
                  summaryReportingSettings {
                    dailyConcerningActivity
                    dailyFacilitySummary
                    weeklyFacilitySummary
                    weeklyRegionalSummary
                  }
                }
              }
            }
          }
        }

        ... on IncidentEscalationMatrix {
          doorSensorConfigs {
            threshold
            sensorType
            doorType
            thresholdSign
            description
            organizationSubtype
            organizationType
            priority
            schedule
            shortCode
          }
          motionSensorConfigs {
            sensorType
            authorization
            description
            organizationSubtype
            priority
            rentStatus
            renterStatus
            multi
            organizationType
            schedule
            threshold
            thresholdSign
            shortCode
          }
        }
      }
    }
  }
`;

export const UPDATE_ORGANIZATION_FEATURE = gql`
  mutation UpdateOrganizationFeature(
    $id: ID!
    $organizationId: ID!
    $config: InputFeatureConfig!
  ) {
    updateOrganizationFeature(
      id: $id
      organizationId: $organizationId
      config: $config
    ) {
      id
      config {
        ... on IncidentPriorityMatrix {
          incidentPriorityMatrix {
            name
            level
            commonSettings {
              createAutoTask
              approvalRequired
              incidentOpenIn
              incidentProgressIn
              incidentResolvedIn
              incidentReviewedIn
              inspectionRequired
            }
            incidentSettings {
              taskAssignmentRole
              assigneeForApprovalRole
              type
              notificationSettings {
                managerNotificationSettings {
                  managerRole
                  email
                  robocall
                  sms
                  slack
                  teams
                  summaryReportingSettings {
                    dailyConcerningActivity
                    dailyFacilitySummary
                    weeklyFacilitySummary
                    weeklyRegionalSummary
                  }
                }
              }
            }
          }
        }
        ... on IncidentEscalationMatrix {
          doorSensorConfigs {
            threshold
            sensorType
            doorType
            thresholdSign
            organizationSubtype
            organizationType
            priority
            schedule
            shortCode
          }
          motionSensorConfigs {
            sensorType
            authorization
            organizationSubtype
            priority
            rentStatus
            renterStatus
            multi
            organizationType
            schedule
            threshold
            thresholdSign
            shortCode
          }
        }
      }
    }
  }
`;

export const GET_OVERRIDDEN_ORGANIZATION_FEATURE = gql`
  query GetOverriddenOrganizationFeature(
    $featureName: String
    $organizationId: ID!
  ) {
    overridenOrganizationFeature(
      featureName: $featureName
      organizationId: $organizationId
    ) {
      organization {
        id
        type
      }
      feature {
        id
      }
      config {
        ... on IncidentPriorityMatrix {
          incidentPriorityMatrix {
            name
            level
            commonSettings {
              createAutoTask
              approvalRequired
              incidentOpenIn
              incidentProgressIn
              incidentResolvedIn
              incidentReviewedIn
              inspectionRequired
            }

            incidentSettings {
              assigneeForApprovalRole
              taskAssignmentRole
              type
              notificationSettings {
                managerNotificationSettings {
                  managerRole
                  sms
                  email
                  robocall
                  sms
                  slack
                  teams
                  summaryReportingSettings {
                    dailyConcerningActivity
                    dailyFacilitySummary
                    weeklyFacilitySummary
                    weeklyRegionalSummary
                  }
                }
              }
            }
          }
        }
        ... on IncidentEscalationMatrix {
          doorSensorConfigs {
            threshold
            sensorType
            doorType
            thresholdSign
            description
            organizationSubtype
            organizationType
            priority
            schedule
            shortCode
          }
          motionSensorConfigs {
            sensorType
            authorization
            description
            organizationSubtype
            priority
            rentStatus
            renterStatus
            multi
            organizationType
            schedule
            threshold
            thresholdSign
            shortCode
          }
        }
      }
    }
  }
`;

<template>
  <div>
    <v-row v-for="manager in settings" :key="manager.managerRole" class="mb-6">
      <v-col cols="12">
        <div class="text-h6 mb-3 role-title" :style="{ color: '#1976D2' }">
          {{ titleCase(manager.managerRole || "") }}
        </div>

        <v-row>
          <v-col cols="6">
            <v-card class="pl-4">
              <div class="notification-group">
                <div class="text-subtitle-1 mb-2">Real Time Alerts</div>
                <div class="ml-4">
                  <v-checkbox
                    v-for="alert in realTimeAlerts"
                    :key="alert.key"
                    v-model="manager[alert.key]"
                    :label="alert.label"
                    density="compact"
                    color="primary"
                    hide-details
                    :readonly="readOnly"
                    :disabled="readOnly"
                  />
                </div>
              </div>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card>
              <div class="notification-group">
                <div class="text-subtitle-1 mb-2">Summary Reporting</div>
                <div class="ml-4">
                  <v-checkbox
                    v-for="report in summaryReports"
                    :key="report.key"
                    v-model="manager.summaryReportingSettings[report.key]"
                    :label="report.label"
                    density="compact"
                    color="primary"
                    hide-details
                    :readonly="readOnly"
                    :disabled="readOnly"
                  />
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { titleCase } from "@tod-ui/helpers/strings";

export default {
  name: "NotificationModal",

  props: {
    settings: {
      type: Array,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      realTimeAlerts: [
        { key: "email", label: "Email" },
        { key: "robocall", label: "Robo Call" },
        { key: "slack", label: "Slack" },
        { key: "teams", label: "Teams" },
        { key: "sms", label: "SMS" },
      ],
      summaryReports: [
        {
          key: "dailyConcerningActivity",
          label: "Daily Morning Concerning Activity Email",
        },
        {
          key: "weeklyRegionalSummary",
          label: "Weekly Regional Email Summary",
        },
        {
          key: "dailyFacilitySummary",
          label: "Daily Morning Facility Digest Email",
        },
        {
          key: "weeklyFacilitySummary",
          label: "Weekly Facility Email Summary",
        },
      ],
    };
  },

  methods: {
    titleCase,
  },
};
</script>

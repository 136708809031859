<template>
  <CardLayout v-model="search" title="Incident Surveys" header-type="search">
    <template #top-actions>
      <DateTimeFilter
        v-model="dateRange"
        :time-zone="facilityTimeZone"
        :ranges="['1w', '2w', '1m']"
        default="1m"
        class="mr-2"
      />
    </template>

    <v-data-table
      tile
      class="table-striped"
      :headers="headers"
      :items="incidents"
      :search="search"
      :custom-filter="customFilter"
      :sort-by.sync="sort"
      :footer-props="{ 'items-per-page-options': [10, 25, 50, 500] }"
    >
      <template #item.actions="{ item }">
        <ButtonView @click="viewIncident(item)" />
      </template>
    </v-data-table>
  </CardLayout>
</template>

<script>
import ButtonView from "@atoms/ButtonView.vue";
import CardLayout from "@layout/CardLayout.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";

import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import { getDetailedIncidents } from "../../graphql/incidents";
import useFacility from "../authentication/useFacility";

export default {
  name: "IncidentSurveys",
  components: {
    ButtonView,
    CardLayout,
    DateTimeFilter,
  },
  setup() {
    const {
      currentFacilityId,
      facilityTimeZone,
      facilityFormatDateTime4Humans,
    } = useFacility();
    return {
      currentFacilityId,
      facilityTimeZone,
      facilityFormatDateTime4Humans,
    };
  },
  data: () => ({
    incidents: [],
    dateRange: { after: undefined },
    search: "",
    sort: ["incident.startedAt"],
  }),
  computed: {
    headers() {
      return [
        {
          text: "Details",
          align: "start",
          filterable: false,
          sortable: false,
          value: "actions",
          width: "1%",
        },
        {
          text: "Smart Unit #",
          value: "organization.name",
        },
        {
          text: "Started At",
          value: "startedAt",
        },
        {
          text: "Renter Name",
          value: "renter.name",
        },
        {
          text: "Primary Phone",
          value: "renter.phoneNumber",
          sortable: false,
        },
      ];
    },
    incidentsLoading() {
      return this.$apollo.queries.getDetailedIncidents.loading;
    },
  },
  apollo: {
    incidents: {
      query: getDetailedIncidents,
      fetchPolicy: "no-cache",
      variables() {
        return {
          parentId: this.currentFacilityId,
          dateTimeFilter: this.dateRange,
          organizationSubtype: "unit",
          resolution: "surveyed",
        };
      },
      skip() {
        return !this.dateRange?.after;
      },
      update({ incidents }) {
        return incidents.reduce((acc, incident) => {
          if (incident.startedAt) {
            incident.startedAt = this.facilityFormatDateTime4Humans(
              incident.startedAt
            );
          }

          // Convert first responder to renter
          const responder = incident.responders.find(
            ({ escalationLevel, type }) =>
              escalationLevel === 1 && type === "zone"
          );

          if (responder) {
            const { name } = responder;
            let phoneNumber;

            if (responder.contactMethod) {
              phoneNumber = formatPhoneNumber(responder.contactMethod.value);
            } else if (responder.phoneNumber) {
              phoneNumber = formatPhoneNumber(responder.phoneNumber);
            }

            incident.renter = {
              name,
              phoneNumber,
            };
          }

          acc.push(incident);
          return acc;
        }, []);
      },
    },
  },
  methods: {
    viewIncident(item) {
      this.$router.push({
        name: "Incident",
        params: {
          incident_id: item.id,
        },
      });
    },
    customFilter(value, search, item) {
      if (!search) return true;
      const _search = search.toLowerCase();
      const values = [item.organization.name, item.startedAt];

      if (item.renter) {
        values.push(item.renter.name);
        values.push(item.renter.phoneNumber);
      }

      return values.join(" ").toLowerCase().indexOf(_search) >= 0;
    },
  },
};
</script>

<template>
  <div>
    <v-menu
      v-model="showFilter"
      bottom
      :close-on-click="false"
      :close-on-content-click="false"
      left
      max-width="300px"
      min-width="300px"
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-btn outlined small class="action-btn" v-bind="attrs" v-on="on">
          <v-icon left small>mdi-filter</v-icon>
          Filter
          <template v-if="filterCount > 0">
            <v-divider vertical class="ml-2 mr-2" />
            <span class="primary--text">
              {{ filterCount }}
            </span>
          </template>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar>
          <v-btn small outlined color="grey" @click="clear">Clear</v-btn>
          <v-spacer />
          <v-toolbar-title class="subtitle-1 ml-0 mr-0">Filter</v-toolbar-title>
          <v-spacer />
          <v-btn small depressed color="primary" @click="accept">Done</v-btn>
        </v-toolbar>

        <v-expansion-panels accordion multiple>
          <v-expansion-panel>
            <v-expansion-panel-header> Type </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                <v-checkbox
                  v-for="type in taskTypes"
                  :key="type.value"
                  v-model="filter.taskTypes"
                  multiple
                  :label="type.text"
                  :value="type.value"
                  dense
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header> Location </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-checkbox
                v-for="subtype in zoneSubtypes"
                :key="subtype.value"
                v-model="filter.locations"
                dense
                :label="titleCase(subtype.text)"
                :value="subtype.value"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header> Priority </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-checkbox
                v-for="status in priorities"
                :key="status.value"
                v-model="filter.priorities"
                dense
                :label="titleCase(status.text)"
                :value="status.value"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header> Overdue </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-checkbox
                v-model="filter.overdue"
                label="Overdue"
                dense
                hide-details
                class="mb-2"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { titleCase } from "@tod-ui/helpers/strings";
import { accessStatus } from "../common/helpers/incident";
import { TASK_TYPES } from "./graphql";
const EMPTY_FILTER = {
  locations: [], // unit || zone
  statuses: [], // status + resolution macros
  types: [], // incident type
  afterHours: undefined, // incident sub type(ex. After hours activity)
  accessStatuses: [], // access code status
  priorities: [], // priority
  overdue: undefined, // overdue
};

export default {
  name: "TaskFilter",
  props: {
    autoFilter: {
      type: Object,
      default: () => ({
        status: "",
        dateRange: { after: null, before: null },
      }),
    },
  },

  data: () => ({
    filter: { ...EMPTY_FILTER },
    showFilter: false,
    taskTypes: [],
    zoneSubtypes: [
      { value: "unit", text: "Smart Unit" },
      { value: "zone", text: "Smart Zone" },
    ],
    statuses: [
      { value: "authorized", text: "Authorized" },
      { value: "unauthorized", text: "Unauthorized" },
      { value: "unacknowledged", text: "Unacknowledged" },
    ],
    accessStatuses: [
      "presence_confirmed",
      "unconfirmed_access",
      "bypassed_access",
    ],
    priorities: [
      { value: "critical", text: "Critical" },
      { value: "severe", text: "Severe" },
      { value: "substantial", text: "Substantial" },
      { value: "moderate", text: "Moderate" },
      { value: "low", text: "Low" },
    ],
  }),

  computed: {
    filterCount() {
      let count = 0;
      for (let k in this.filter) {
        const value = this.filter[k];
        if (value !== null && value !== undefined) {
          if (typeof value === "boolean" && value === true) {
            count += 1;
          } else if (Array.isArray(value) && value.length > 0) {
            count += 1;
          }
        }
      }
      return count;
    },
  },

  apollo: {
    taskTypes: {
      query: TASK_TYPES,
      variables() {
        return {
          sort: "name",
          status: "active",
        };
      },
      update(data) {
        const mappedTypes = data.taskTypes.map((type) => ({
          text: titleCase(type.name),
          value: type.id,
        }));
        return mappedTypes;
      },
    },
  },
  watch: {
    "autoFilter.status": {
      handler(newStatus) {
        if (newStatus === "overdue") {
          this.filter.overdue = true;
          this.$emit("apply", { ...this.filter });
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.autoFilter.status === "overdue") {
      this.filter.overdue = true;
      this.$emit("apply", { ...this.filter });
    }
  },
  methods: {
    accept() {
      // Create a clean filter object without false/empty values
      const cleanFilter = { ...this.filter };

      // Remove overdue property if it's false
      if (!cleanFilter.overdue) {
        delete cleanFilter.overdue;
      }

      this.$emit("apply", cleanFilter);
      this.showFilter = false;
    },

    clear() {
      this.filter = { ...EMPTY_FILTER };
      // Emit clean filter without the overdue property
      const cleanFilter = { ...this.filter };
      delete cleanFilter.overdue;
      this.$emit("apply", cleanFilter);
    },
    titleCase,
    accessStatus,
  },
};
</script>

<style lang="scss" scoped>
.v-input {
  margin-top: 0px;
}
.v-input--dense {
  margin-bottom: -18px;
}
.active {
  background: lightblue;
}
</style>

<template>
  <v-card :id="id" class="w-100 black-border">
    <v-card-text class="pt-2 pb-0">
      <v-row dense style="flex-direction: row-reverse">
        <v-col md="3" align="right">
          <div class="rounded-bg-tile">
            <v-icon :size="$vuetify.breakpoint.xs ? 30 : 27" color="white">
              {{ icon }}
            </v-icon>
          </div>
        </v-col>
        <v-col md="9" align="left">
          <h4 class="black--text text-md-h4 text-h5">
            <template v-if="loading">
              <v-progress-circular indeterminate color="black" />
            </template>
            <template v-else>{{ value }}</template>
          </h4>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col md="12" align="left">
          <p class="black--text text-subtitle-1">{{ title }}</p>
        </v-col>
      </v-row>
    </v-card-text>
    <v-btn :color="color" block @click="$emit('click', id)">
      <v-row>
        <v-col align="left" md="10">
          <v-toolbar-title alight="left" class="body-2 black--text">
            View Details
          </v-toolbar-title>
        </v-col>
        <v-col md="2">
          <v-icon
            small
            align="right"
            class="icon-details"
            :class="{ open: details }"
            color="grey darken-4"
            >fas fa-arrow-circle-right</v-icon
          >
        </v-col>
      </v-row>
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: "InfoCard",
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: "#31708f",
    },
    color: {
      type: String,
      default: "#AAAAAA",
    },
    icon: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    details: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [Object, String, Number],
      required: true,
    },
  },
  computed: {
    tileBackgroundColor() {
      return this.color;
    },
  },
};
</script>

<style>
.rounded-bg-tile {
  background-color: v-bind(tileBackgroundColor);
  border-radius: 50px;
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

import gql from "graphql-tag";
import {
  incidentFields,
  deviceFields,
  organizationFields,
} from "../../graphql/fragments";

export const GET_INCIDENT = gql`
  query Incident($id: ID!) {
    incident(id: $id) {
      ...IncidentFields

      organization {
        ...OrganizationFields
        responders {
          id
          escalationLevel
          user {
            id
            firstName
            lastName
          }
        }
        devices {
          ...DeviceFields
        }
      }
      tasks {
        id
        taskId
        description
        dueAt
        insertedAt
        priority
        resolution
        title
        updatedAt
        assignee {
          id
          firstName
          lastName
          username
        }
      }
      alerting_strategy {
        phone_number
        time_zone
        alert_templates {
          id
          name
        }
        application_org {
          id
          name
          type
        }
        device_org {
          id
          name
          type
        }
        facility_org {
          id
          name
          type
        }
      }
      devices {
        id
        externalId
        shortId
        name
        organization {
          id
          name
        }
      }
      responders {
        id
        escalationLevel
        name
        type
        status
        contactMethod {
          id
          type
          value
        }
      }
      events {
        data
        name
        timestamp
        type
      }
      zoneProfile {
        climateConfig {
          disabled
          highHumidityThreshold
          highTemperatureThreshold
          lowHumidityThreshold
          lowTemperatureThreshold
        }
      }
      survey
    }
  }
  ${deviceFields}
  ${incidentFields}
  ${organizationFields}
`;

export const CREATE_INCIDENT_SURVEY = gql`
  mutation CreateIncidentSurvey(
    $incidentId: ID!
    $organizationId: ID!
    $type: String!
    $survey: String!
  ) {
    createIncidentSurvey(
      incidentId: $incidentId
      organizationId: $organizationId
      type: $type
      survey: $survey
    ) {
      status
    }
  }
`;

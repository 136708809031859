<template>
  <v-container fluid>
    <v-row dense>
      <v-col v-for="(field, index) in timeFields" :key="index" cols="auto">
        <div class="d-flex flex-column">
          <div class="text-bold mb-1 font-weight-bold">{{ field.label }}</div>
          <v-row no-gutters class="mr-3 pr-5" style="width: 200px">
            <v-col cols="7">
              <v-select
                v-model="field.unit"
                :disabled="fieldDisabled || field.disabled"
                :items="timeUnits"
              />
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="field.value"
                :disabled="fieldDisabled || field.disabled"
                type="number"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col
        v-for="(checkbox, index) in checkboxFields"
        :key="`checkbox-${index}`"
        cols="auto"
      >
        <div class="d-flex flex-column align-center" style="min-width: 120px">
          <div class="text-bold mb-1 font-weight-bold">
            {{ checkbox.label }}
          </div>
          <v-checkbox
            v-model="checkbox.value"
            :disabled="fieldDisabled"
            density="compact"
            hide-details
          />
        </div>
      </v-col>
    </v-row>

    <v-row class="mb-2">
      <v-col
        v-for="(header, index) in tableHeaders"
        :key="index"
        :cols="header.cols"
      >
        <div class="text-subtitle-2 font-weight-bold">{{ header.title }}</div>
      </v-col>
    </v-row>

    <v-row v-for="row in tableRows" :key="row.type" align="center">
      <v-col cols="2">{{ row.type }}</v-col>
      <v-col cols="2">
        <v-select
          v-model="row.taskAssignmentRole"
          :items="roleOptions"
          density="compact"
          variant="outlined"
          hide-details
          class="mb-2"
          bg-color="white"
          :disabled="fieldDisabled"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="row.assigneeForApprovalRole"
          :items="roleOptions"
          density="compact"
          variant="outlined"
          hide-details
          class="mb-2"
          bg-color="white"
          :disabled="fieldDisabled"
        />
      </v-col>
      <v-col cols="2">
        <v-btn
          variant="outlined"
          size="small"
          @click="openNotificationModal(row)"
        >
          View Default
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn
          variant="outlined"
          size="small"
          @click="viewAssignedIncidents(row.type)"
          >View</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-center">
        <v-btn :disabled="fieldDisabled" color="success" @click="handleSave"
          >Save</v-btn
        >
      </v-col>
    </v-row>

    <DialogLayout
      v-model="isOpenNotificationModal"
      title="Notification Settings"
      width="900px"
    >
      <NotificationModal
        :read-only="fieldDisabled"
        :settings="notificationLocalSettings"
      />
      <template #actions>
        <div class="d-flex justify-center">
          <v-btn
            :disabled="fieldDisabled"
            color="primary"
            class=" "
            :loading="saving"
            @click="saveNotificationSettings"
          >
            Save Changes
          </v-btn>
        </div>
      </template>
    </DialogLayout>
  </v-container>
</template>

<script>
import { UPDATE_ORGANIZATION_FEATURE } from "./graphql";
import { ref } from "vue";
import DialogLayout from "../../components/layout/DialogLayout.vue";
import NotificationModal from "./NotificationModal.vue";
import useFacility from "../authentication/useFacility";
import useUser from "../authentication/useUser";
import { secondsToUnit, convertToSeconds } from "./helper";
export default {
  name: "CardPriorityContent",
  components: {
    DialogLayout,
    NotificationModal,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    allPriorityData: {
      type: Array,
      required: true,
    },
    featureId: {
      type: String,
      default: null,
    },
    fieldDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const isOpenNotificationModal = ref(false);
    const selectedNotificationSettings = ref(null);
    const selectedIncidentType = ref(null);
    const notificationLocalSettings = ref([]);
    const { currentFacilityId, facilityFeatures } = useFacility();
    const { defaultOrganization } = useUser();

    return {
      defaultOrganization,
      currentFacilityId,
      isOpenNotificationModal,
      selectedNotificationSettings,
      selectedIncidentType,
      notificationLocalSettings,
      facilityFeatures,
    };
  },
  data() {
    return {
      localTableRows: [],
      timeUnits: ["Hours", "Days", "Weeks", "Months"],
      saving: false,
      localSettings: {},
      showNotificationModal: false,
      roleOptions: [
        { text: "Facility Manager", value: "facility_manager" },
        { text: "Regional Manager", value: "regional_manager" },
        { text: "Organization Manager", value: "organization_manager" },
      ],
    };
  },

  computed: {
    timeFields() {
      const commonSettings = this.settings?.commonSettings || {};
      const openIn = this.secondsToUnit(commonSettings.incidentOpenIn);
      const progressIn = this.secondsToUnit(commonSettings.incidentProgressIn);
      const reviewedIn = this.secondsToUnit(commonSettings.incidentReviewedIn);
      const totalSeconds =
        (commonSettings.incidentOpenIn || 0) +
        (commonSettings.incidentProgressIn || 0) +
        (commonSettings.incidentReviewedIn || 0);

      const resolvedIn = this.secondsToUnit(totalSeconds);

      return [
        {
          label: "Incident Open In",
          value: openIn.value,
          unit: openIn.unit,
        },
        {
          label: "Incident Progress In",
          value: progressIn.value,
          unit: progressIn.unit,
        },
        {
          label: "Incident Reviewed In",
          value: reviewedIn.value,
          unit: reviewedIn.unit,
        },
        {
          label: "Incident Resolved In",
          value: resolvedIn.value,
          unit: resolvedIn.unit,
          disabled: true,
        },
      ];
    },
    checkboxFields() {
      const commonSettings = this.settings?.commonSettings || {};
      return [
        {
          label: "Create Auto Task",
          value: commonSettings.createAutoTask || false,
        },
        {
          label: "Inspection Required",
          value: commonSettings.inspectionRequired || false,
        },
        {
          label: "Approval Required",
          value: commonSettings.approvalRequired || false,
        },
      ];
    },
    tableHeaders() {
      return [
        { title: "Incident Category", cols: 2 },
        { title: "Task Assignment Role", cols: 2 },
        { title: "Assignee for Approval Role", cols: 3 },
        { title: "Notification Preference", cols: 2 },
        { title: "View Assigned Incidents", cols: 3 },
      ];
    },
    tableRows: {
      get() {
        if (this.localTableRows.length) {
          return this.localTableRows;
        }

        const incidentSettings = this.settings?.incidentSettings || [];
        return ["Motion", "Entry"].map((type, index) => ({
          type,
          taskAssignmentRole:
            incidentSettings[index]?.taskAssignmentRole || "facility_manager",
          assigneeForApprovalRole:
            incidentSettings[index]?.assigneeForApprovalRole ||
            "regional_manager",
          notificationSettings:
            incidentSettings[index]?.notificationSettings
              ?.managerNotificationSettings || this.defaultNotificationSettings,
        }));
      },
      set(newValue) {
        this.localTableRows = newValue;
      },
    },
  },
  watch: {
    timeFields: {
      deep: true,
      handler(newFields) {
        const changedField = newFields.find(
          (field) =>
            field.label !== "Incident Resolved In" &&
            field.value !==
              this.timeFields.find((f) => f.label === field.label)?.value
        );

        if (changedField) {
          const totalSeconds = newFields
            .filter((field) => field.label !== "Incident Resolved In")
            .reduce((sum, field) => {
              return sum + this.convertToSeconds(field.value, field.unit);
            }, 0);
        }
      },
    },
    "settings.incidentSettings": {
      immediate: true,
      handler(newSettings) {
        if (!newSettings) return;

        this.localTableRows = ["Motion", "Entry"].map((type, index) => ({
          type,
          taskAssignmentRole:
            newSettings[index]?.taskAssignmentRole || "facility_manager",
          assigneeForApprovalRole:
            newSettings[index]?.assigneeForApprovalRole || "regional_manager",
          notificationSettings:
            newSettings[index]?.notificationSettings
              ?.managerNotificationSettings || this.defaultNotificationSettings,
        }));
      },
    },
    isOpenNotificationModal(newValue) {
      if (!newValue) {
        this.closeNotificationModal();
      }
    },
  },

  methods: {
    cleanTypename(data) {
      if (Array.isArray(data)) {
        return data.map((item) => this.cleanTypename(item));
      }

      if (data && typeof data === "object") {
        const cleaned = {};
        for (const [key, value] of Object.entries(data)) {
          if (key !== "__typename") {
            cleaned[key] = this.cleanTypename(value);
          }
        }
        return cleaned;
      }

      return data;
    },

    openNotificationModal(row) {
      this.selectedIncidentType = row.type;
      const cleanSettings = row.notificationSettings?.length
        ? this.cleanTypename(row.notificationSettings)
        : this.getDefaultNotificationSettings();

      this.notificationLocalSettings = cleanSettings;
      this.isOpenNotificationModal = true;
    },

    closeNotificationModal() {
      this.isOpenNotificationModal = false;
      this.notificationLocalSettings = [];
      this.selectedIncidentType = null;
    },
    viewAssignedIncidents(incidentType) {
      this.$emit("view-assigned-incidents", incidentType, this.name);
    },
    async saveNotificationSettings() {
      try {
        this.saving = true;
        this.localTableRows = this.localTableRows.map((row) => {
          if (row.type === this.selectedIncidentType) {
            return {
              ...row,
              notificationSettings: this.notificationLocalSettings,
            };
          }
          return row;
        });

        await this.handleSave();
        this.closeNotificationModal();
      } catch (error) {
        console.error("Error saving notification settings:", error);
      } finally {
        this.saving = false;
      }
    },

    async handleSave() {
      try {
        this.saving = true;

        const updatedPriority = this.allPriorityData.map((item) => {
          if (item.name === this.name) {
            const openInField = this.timeFields.find(
              (f) => f.label === "Incident Open In"
            );
            const progressInField = this.timeFields.find(
              (f) => f.label === "Incident Progress In"
            );
            const reviewedInField = this.timeFields.find(
              (f) => f.label === "Incident Reviewed In"
            );
            const resolvedInField = this.timeFields.find(
              (f) => f.label === "Incident Resolved In"
            );

            const updatedIncidentSettings = item.incidentSettings.map(
              (setting) => {
                const matchingRow = this.localTableRows.find(
                  (row) => row.type.toLowerCase() === setting.type.toLowerCase()
                );

                return {
                  ...setting,
                  type: setting.type,
                  taskAssignmentRole:
                    matchingRow?.taskAssignmentRole ||
                    setting.taskAssignmentRole,
                  assigneeForApprovalRole:
                    matchingRow?.assigneeForApprovalRole ||
                    setting.assigneeForApprovalRole,
                  notificationSettings: {
                    managerNotificationSettings:
                      matchingRow?.notificationSettings ||
                      setting.notificationSettings?.managerNotificationSettings,
                  },
                };
              }
            );

            return {
              name: this.name,
              level: this.level,
              commonSettings: {
                createAutoTask: this.checkboxFields.find(
                  (f) => f.label === "Create Auto Task"
                )?.value,
                approvalRequired: this.checkboxFields.find(
                  (f) => f.label === "Approval Required"
                )?.value,
                inspectionRequired: this.checkboxFields.find(
                  (f) => f.label === "Inspection Required"
                )?.value,
                incidentOpenIn: this.convertToSeconds(
                  openInField?.value,
                  openInField?.unit
                ),
                incidentProgressIn: this.convertToSeconds(
                  progressInField?.value,
                  progressInField?.unit
                ),
                incidentResolvedIn: this.convertToSeconds(
                  resolvedInField?.value,
                  resolvedInField?.unit
                ),
                incidentReviewedIn: this.convertToSeconds(
                  reviewedInField?.value,
                  reviewedInField?.unit
                ),
              },
              incidentSettings: updatedIncidentSettings,
            };
          }
          return item;
        });

        const cleanedPriority = this.cleanTypename(updatedPriority);

        const result = await this.$apollo.mutate({
          mutation: UPDATE_ORGANIZATION_FEATURE,
          variables: {
            id: this.featureId,
            organizationId: this.defaultOrganization.id,
            config: {
              incidentPriorityMatrix: {
                incidentPriorityMatrix: cleanedPriority,
              },
            },
          },
        });

        this.$emit("save-success", result);
      } catch (error) {
        console.error("Save error:", error);
        this.$emit("save-error", error);
      } finally {
        this.saving = false;
      }
    },
    secondsToUnit,
    convertToSeconds,
  },
};
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <div class="bordered-card mb-3">
          <v-row class="align-center">
            <v-col cols="12">
              <div class="mb-3">
                <v-icon
                  class="fab fa-slack"
                  color="#4A154B"
                  style="font-size: 60px"
                />
              </div>
              <strong class="text-h6"
                >Slack Connector for StorageDefender</strong
              >
              <div class="subtitle-2 text-grey mb-1">
                The ultimate Slack integration for StorageDefender integrations,
                messaging, utilities, workflow
              </div>

              <v-divider class="my-4" />
              <v-row class="align-center justify-center">
                <v-icon small class="mr-1">mdi-shield</v-icon>
                <span>Cloud security participant</span>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PageApps",
};
</script>

<style scoped>
.bordered-search {
  border-bottom: 3px solid lightblue;
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 16px;
}
.bordered-card {
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 4px;
}
</style>

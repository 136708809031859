<template>
  <v-card outlined tile class="black-border">
    <v-card-title
      class="subtitle-1 pa-2"
      style="background-color: #eee; border-bottom: 1px solid black"
    >
      <v-icon left small class="d-none d-lg-flex">{{ icon }}</v-icon>
      <span class="ml-2">{{ title }}</span>
    </v-card-title>

    <v-card-text class="mt-4" style="max-height: 276px; min-height: 276px">
      <v-skeleton-loader type="list-item@5" :loading="incidentsLoading">
        <v-list
          flat
          tile
          outlined
          two-line
          class="overflow-y-auto"
          style="max-height: 276px; min-height: 276px"
        >
          <template v-if="incidents && incidents.length > 0">
            <v-list-item
              v-for="incident in incidents"
              :key="incident.id"
              class="incident-row"
              @click="gotoIncident(incident.id)"
            >
              <v-list-item-icon class="ma-0 mr-4 align-self-center">
                <IconIncidentType :incident="incident" />
              </v-list-item-icon>

              <v-list-item-content class="py-0">
                <v-list-item-title>{{
                  formatIncidentType(incident)
                }}</v-list-item-title>
                <v-list-item-subtitle
                  :title="incidentDateTime(incident, false)"
                >
                  @ <strong>{{ incident.organization.name }}</strong>
                  {{ incidentDateTime(incident, true) }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <IconIncidentStatus right small :incident="incident" />
              </v-list-item-action>
            </v-list-item>
          </template>

          <v-list-item v-else>
            <v-list-item-icon class="mr-3">
              <v-icon>far fa-calendar-check</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <span>No Recent Activity</span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-skeleton-loader>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <DateTimeFilter
        v-model="dateRange"
        :time-zone="facilityTimeZone"
        :ranges="['Today', '2d', '1w']"
        default="2d"
        class="mr-2"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import IconIncidentStatus from "@atoms/IconIncidentStatus.vue";
import IconIncidentType from "@atoms/IconIncidentType.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";

import { formatIncidentType } from "../common/helpers/incident";

import useFacility from "@components/authentication/useFacility";
import useDashboardIncidents from "./useDashboardIncidents";
import { ref } from "vue";

export default {
  name: "CardRecentIncidents",
  components: {
    IconIncidentStatus,
    IconIncidentType,
    DateTimeFilter,
  },
  props: {
    incidentType: {
      validator: (val) =>
        val === "active-units" ||
        val === "vacant-units" ||
        val === "after-hours",
      default: "active",
    },
  },
  setup(props) {
    const {
      currentFacilityId,
      facilityFormatDateTime4Humans,
      facilityTimeZone,
    } = useFacility();
    const { fetchRecentIncidents, recentIncidents, recentIncidentsLoading } =
      useDashboardIncidents();
    const dateRange = ref({ after: undefined });
    const icon =
      props.incidentType === "after-hours"
        ? "fas fa-moon"
        : props.incidentType === "vacant-units"
        ? "fas fa-table"
        : "fas fa-bell";
    const title =
      props.incidentType === "after-hours"
        ? "After Hours Activity"
        : props.incidentType === "vacant-units"
        ? "Vacant Smart Unit Activity"
        : "Active Smart Unit Activity";

    function incidentDateTime(incident, short) {
      return facilityFormatDateTime4Humans(incident.startedAt, short);
    }
    return {
      incidents: recentIncidents[props.incidentType],
      incidentsLoading: recentIncidentsLoading[props.incidentType],
      fetchRecentIncidents,
      dateRange,
      icon,
      title,
      incidentDateTime,
      facilityTimeZone,
      currentFacilityId,
    };
  },
  watch: {
    dateRange(value) {
      this.fetchRecentIncidents(this.$apollo, this.incidentType, value?.after);
    },
  },
  methods: {
    formatIncidentType,
    gotoIncident(incidentId) {
      this.$router.push({
        name: "Incident",
        params: {
          id: this.currentFacilityId,
          incident_id: incidentId,
        },
      });
    },
  },
};
</script>

<style scoped>
.incident-row {
  min-height: 48px;
}
.v-list-item__title {
  font-size: 0.875rem;
  font-weight: 500;
}
</style>

import gql from "graphql-tag";
import { incidentFields, organizationFields } from "../../graphql/fragments";

export const FACILITY_SUBSCRIPTIONS = gql`
  query FacilitySubscriptions(
    $organizationId: ID!
    $dateTimeFilter: DateTimeFilter
  ) {
    facilitySubscriptions(
      organizationId: $organizationId
      dateTimeFilter: $dateTimeFilter
    ) {
      activeSmartUnits
      activeSubscriptions
      added
      ended
    }
  }
`;

export const INCIDENT_HISTORY = gql`
  query IncidentHistory(
    $parentId: ID!
    $dateTimeFilter: DateTimeFilter
    $organizationSubtypes: [String]
    $resolutions: [String]
    $statuses: [String]
    $types: [String]
    $duringAccessHours: Boolean
    $bypassedAccess: Boolean
    $assigned: Boolean
    $accessStatuses: [String]
    $searchText: String
  ) {
    incidents(
      parentId: $parentId
      dateTimeFilter: $dateTimeFilter
      organizationSubtypes: $organizationSubtypes
      resolutions: $resolutions
      statuses: $statuses
      types: $types
      duringAccessHours: $duringAccessHours
      assigned: $assigned
      bypassedAccess: $bypassedAccess
      accessStatuses: $accessStatuses
      searchText: $searchText
      sort: "startedAt desc"
    ) {
      ...IncidentFields
      survey
      organization {
        ...OrganizationFields
      }
      tasks {
        id
        taskId
        description
        dueAt
        insertedAt
        priority
        resolution
        title
        updatedAt
        assignee {
          id
          firstName
          lastName
          username
        }
      }
      alerting_strategy {
        phone_number
        time_zone
        alert_templates {
          id
          name
        }
        application_org {
          id
          name
          type
        }
        device_org {
          id
          name
          type
        }
        facility_org {
          id
          name
          type
        }
      }
      zone_profile {
        climateConfig {
          disabled
          highHumidityThreshold
          highTemperatureThreshold
          lowHumidityThreshold
          lowTemperatureThreshold
        }
        entryConfig {
          disabled
          schedule
          startsAt
          endsAt
        }
        motionConfigs {
          disabled
          mode
          schedule
          startsAt
          endsAt
        }
        serviceRequestConfig {
          disabled
          schedule
          startsAt
          endsAt
        }
        videoConfig {
          disabled
          videoUrl
          videoCameraEvents
        }
      }
      alerts {
        id
        external_id
        status
        type
        escalation_level
        to
        from
        body
        template_id
        response_mode
        events {
          id
          data
          name
          timestamp
          type
        }
        responder {
          id
          name
          type
          escalationLevel
          contactMethod {
            id
            type
            value
          }
        }
      }
      devices {
        id
        external_id
        short_id
        name
        organization_id
        triggered
        type
        location
      }
      events {
        id
        data
        name
        timestamp
        type
      }
      responders {
        id
        escalationLevel
        name
        type
        status
        contactMethod {
          id
          type
          value
        }
      }
    }
  }
  ${incidentFields}
  ${organizationFields}
`;

export const FACILITY_REPORT_INCIDENTS = gql`
  query FacilityReportIncidents($parentId: ID!, $after: DateTime!) {
    incidents(
      parentId: $parentId
      dateTimeFilter: { after: $after }
      organizationSubtype: "unit"
      sort: "startedAt asc"
    ) {
      ...IncidentFields
    }
  }
  ${incidentFields}
`;

export const TENANT_LEDGER_REPORT_STATUS = gql`
  query tenantLedgerLogs(
    $organizationId: ID!
    $dateTimeFilter: DateTimeFilter!
  ) {
    tenantLedgerLogs(
      organizationId: $organizationId
      dateTimeFilter: $dateTimeFilter
      sort: "id desc"
    ) {
      id
      nameOfReport
      facilityId
      dateOfReport
      initialEmailSentStatus
      emailResults
      statusOfReport
      organizationId
    }
  }
`;

export const TENANT_LEDGER_PMS_REPORT = gql`
  query tenantLedgerPmsReport(
    $organizationId: ID!
    $dateTimeFilter: DateTimeFilter!
    $pmsName: String
  ) {
    tenantLedgerPmsReport(
      organizationId: $organizationId
      dateTimeFilter: $dateTimeFilter
      pmsName: $pmsName
      sort: "id desc"
    ) {
      corpCode
      facilityId
      insertedAt
      sdChargeAmount
      sdChargeId
      sdChargeReconcilation
      sdEndDate
      sdStartDate
      sdUnitId
      sdUnitName
      siteId
      organizationId
      ledgerId
    }
  }
`;

export const ORGANIZATION_PROFILE = gql`
  query Organization($id: ID!) {
    organization(id: $id) {
      id
      profile {
        ... on DefaultProfile {
          email
          extension
          phoneNumber
          autoEmailForScheduler
          enableTenantLedger
          tenantLedgerReportType
          enableRecurringchargeReconciliationReport
          reconciliationReportType
          autoEmailForReconciliationReport
        }
      }
    }
  }
`;

export const SEND_TENANT_LEDGER_EMAIL = gql`
  query sendEmail($organizationId: ID!, $nowUtc: DateTime, $email: String!) {
    sendEmail(organizationId: $organizationId, nowUtc: $nowUtc, email: $email) {
      status
      message
    }
  }
`;

export const RECONCILIATION_REPORT = gql`
  query ReconciliationReport(
    $organizationId: ID!
    $dateTimeFilter: DateTimeFilter!
    $facilityIds: [ID]!
    $chargeStatus: String
  ) {
    reconciliationReport(
      organizationId: $organizationId
      dateTimeFilter: $dateTimeFilter
      sort: "id desc"
      facilityIds: $facilityIds
      chargeStatus: $chargeStatus
    ) {
      id
      organizationId
      facilityId
      facilityName
      sdStartDate
      sdUnitName
      sdEndDate
      sdRecurringChargeName
      sdChargeReconcilation
      sdDeviceAssigned
      sdChargeAmount
    }
  }
`;

export const SEND_RECURRING_CHARGE_REPORT_EMAIL = gql`
  mutation sendRecurringChargeEmail(
    $organizationId: ID!
    $dateTimeFilter: DateTimeFilter!
    $email: String!
    $facilityIds: [ID]!
    $chargeStatus: String
  ) {
    sendRecurringChargeEmail(
      organizationId: $organizationId
      dateTimeFilter: $dateTimeFilter
      email: $email
      facilityIds: $facilityIds
      chargeStatus: $chargeStatus
    ) {
      status
      message
    }
  }
`;

<template>
  <v-sheet class="pa-4">
    <h1 class="headline mb-2">Help Resources</h1>
    <v-card flat>
      <v-card-title> Training Videos: </v-card-title>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <b>Dashboard User Training</b>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <YoutubeVideos
              :videos="[
                {
                  id: '2Il7TOTzzn4',
                  category: 'StorageDefender Training',
                  title: `How does a Smart Unit work?`,
                },
                {
                  id: 'U7u8tiT7V4w',
                  category: 'StorageDefender Training',
                  title: `¿Cómo funciona su Mini Bodega Inteligente?`,
                },
                {
                  id: '9Lqrag0ZsHU',
                  category: 'StorageDefender Training',
                  title: `The What and Why of StorageDefender`,
                },
                {
                  id: '1z66ByYWGKA',
                  category: 'StorageDefender Training',
                  title: `Quick Start Intro & Dashboard Training`,
                },
                {
                  id: 'ebW5aAEwQ9U',
                  category: 'StorageDefender Training',
                  title: `Dashboard Overview for Sitelink Users`,
                },
                {
                  id: 'nnYIrmY1rYo',
                  category: 'StorageDefender Training',
                  title: `How Do Escalations Work?`,
                },
                {
                  id: 'gwV9yaKGtHs',
                  category: 'StorageDefender Training',
                  title: `Inventory Management`,
                },
                {
                  id: 'fTu5YzX2VwI',
                  title: `StorageDefender Smart Unit Monitoring Company Vision and Solution by Mark Cieri, CEO & Co-Founder`,
                },
              ]"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <b>StorageDefender Training: How to Sell Smart Units</b>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <YoutubeVideos
              :videos="[
                {
                  id: 'h2LdHD4zZJM',
                  category: 'StorageDefender Training',
                  title: `How to Sell StorageDefender`,
                },
                {
                  id: 'mNa5r7sRJcA',
                  category: 'StorageDefender Training',
                  title: `The Do’s and Don’ts`,
                },
                {
                  id: '_jghW_C6EPQ',
                  category: 'StorageDefender Training',
                  title: `Focus on Wants vs. Needs and Service vs. Device`,
                },
              ]"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <b>StorageDefender Training: Mounting Smart Devices in Units</b>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <YoutubeVideos
              :videos="[
                {
                  id: 'VNnCHl4n8Hk',
                  category: 'StorageDefender Training',
                  title: `How to Mount Smart Devices in Units`,
                },
              ]"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <b
              >StorageDefender Training: Suggestions on Dealing with Customer
              Objections
            </b>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <YoutubeVideos
              :videos="[
                {
                  id: 'OxKhXL-gFdo',
                  title: `Part 1 - Handling Customer Objections: “This is a bit too expensive”`,
                },
                {
                  id: 'XB6Hg9GcFbY',
                  title: `Part 2 - “This is a bit too expensive” when a customer is a bit older or especially not tech-savvy`,
                },
                {
                  id: 'R7sRgEBbifI',
                  title: `Part 3 - Handling Objections: “I don’t believe the facility has a security problem does it?”`,
                },
                {
                  id: 'MCBgsvhDqUY',
                  title: `Part 4 - Handling Customer Objections: “I don’t know if I need a smart unit”`,
                },
                {
                  id: 'YybxXPh-AnU',
                  title: `Part 5 - Handling Customer Objections: “This sounds complicated, I don’t want to be bothered”`,
                },
                {
                  id: 'fFV1ZA5EQTs',
                  title: `Part 6 - Handling Customer Objections: “This is far too expensive”`,
                },
              ]"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <br />
    <v-card flat>
      <v-card-title> Select a Help Topic: </v-card-title>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header
            ><b>Selling Scripts at the Counter</b></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Smart Unit Selling Script for New Tenants
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>After:</p>
                  <p class="ml-6">
                    1. <span class="highlight">Welcoming</span> Potential
                    Customer<br />
                    2. <span class="highlight">Qualifying</span> &amp;
                    Understanding Customer Needs<br />
                  </p>

                  <p>Then:</p>
                  <p class="ml-6">
                    3. <span class="highlight">Offer Smart Unit</span>
                  </p>

                  <p>
                    Ok, We have unit XXX, which is a 10x10 smart unit available
                    for $100.
                  </p>

                  <p>
                    Your 10x10 smart unit includes our StorageDefender smart
                    monitoring service that provides added Peace of Mind. What’s
                    really great about StorageDefender is that it’s like having
                    a personal superhero watching your valuables 24/7 giving you
                    instant text notifications whenever your unit is accessed.
                    It even has an audible beeper to deter potential intruders
                    after hours.
                  </p>

                  <p>Will this unit work for you?</p>

                  <p class="ml-6">
                    4.
                    <span class="highlight"
                      >Upselling Insurance “Coverage”</span
                    >
                  </p>

                  <p>
                    Excellent. Our facility requires customers to have adequate
                    coverage protecting their valuables, whether our
                    comprehensive StorageDefender Tenant Insurance (or insert
                    name) or your own Homeowners Insurance. As you have a Smart
                    Unit, you will also have the added benefit of the $100
                    deductible being waived, should you ever have a claim.
                  </p>

                  <p>
                    What do you believe is the value of your contents that you
                    are going to store?
                  </p>

                  <p class="red--text">
                    During the process, please point to the selling mat:
                  </p>

                  <ul class="red--text">
                    <li>help customers determine coverage amount</li>
                    <li>
                      point out a few of the coverage areas, such as loss due
                      theft, rodent, mold/mildew protection
                    </li>
                    <li>answer any questions the customer has</li>
                    <li>
                      make sure <u>not to only select the lowest</u> coverage
                      but the most appropriate value for their needs.
                    </li>
                    <li>
                      <u>Remember</u>, this is an excellent money maker for the
                      facility
                    </li>
                  </ul>

                  <p>When complete, confirm with:</p>

                  <p>
                    Good choice, I see you are taking the $4000 coverage for
                    $14.00 per month.
                  </p>

                  <p class="ml-6">
                    5. <span class="highlight">Reviewing Smart Unit Lease</span>
                  </p>

                  <p>
                    Ok great, so your 10x10 unit’s total price comes to $114
                    plus taxes for the first two months:
                  </p>

                  <ul>
                    <li>The Storage Unit is $90</li>
                    <li>Smart Unit Monitoring service is $10</li>
                    <li>The Insurance is $14.00</li>
                  </ul>

                  <p>
                    Would you please sign on these pages unless you have any
                    questions?
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Upgrading Existing Tenants to StorageDefender
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p>After:</p>
                  <p class="ml-6">
                    1. <span class="highlight">Welcoming</span> Existing
                    Tenant<br />
                    2. <span class="highlight">Qualifying</span> &amp;
                    Understanding Tenant’s Needs<br />
                  </p>

                  <p>Then:</p>
                  <p class="ml-6">
                    3. <span class="highlight">Position for Upgrade</span>
                  </p>

                  <p>
                    Ms. Smith, I want to bring to your attention that we have a
                    great new service our tenants really value that turns your
                    storage unit into a SMART UNIT.
                  </p>

                  <p>
                    A SMART UNIT is equipped with the StorageDefender monitoring
                    technology. What’s really great about StorageDefender it
                    gives you the power to monitor your valuables 24/7 giving
                    you instant text notifications whenever your unit is
                    accessed. It even has an audible beeper to deter potential
                    intruders after hours.
                  </p>

                  <p>
                    We’re now offering a special so you can experience the
                    service for your next two months for free, which is normally
                    priced at $10 per month ! I also want to let you know that
                    our Smart Unit tenants also benefit by the waiving of the
                    $100 tenant insurance deductible should they ever have a
                    claim.
                  </p>

                  <p class="highlight">Closing:</p>

                  <p>
                    Would you like me to add this to your lease and set up your
                    unit now? It will only take a few minutes to
                    <span class="red--text">turn on the service</span> in your
                    unit.
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            ><b>Selling Scripts on the Phone</b></v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header> Intro </v-expansion-panel-header>
                <v-expansion-panel-content>
                  “Thank you for calling FACILITY NAME HERE. My name is YOUR
                  NAME, how may I help you?” <br />
                  <br />
                  <u
                    >*If they are looking to rent use <b>SCRIPT A</b>, if they
                    are an existing customer, use <b>SCRIPT B</b>.*</u
                  >
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  A. Call Script for a New Renter
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <u
                    >*Ask them what they are planning on storing so that you can
                    suggest the appropriate size. *</u
                  >
                  <br />
                  <br />
                  “The size that I would suggest is
                  <b><i>“UNIT SIZE”</i></b> and we have a
                  <b><i>SMART UNIT</i></b> available in that size.”
                  <br />
                  <br />
                  “A <b><i>SMART UNIT</i></b> is our
                  <u>state-of-the-art secured storage unit</u> that will alert
                  you, 24/7, if there is any motion detected in your unit. Once
                  detected it will send you an alert through mobile text asking
                  if it was you, to which you reply ‘yes’ or ‘no’. If yes, then
                  it cancels the alert but if you reply no, then you can decide
                  to take action if you have a concern, stop into your unit, or
                  contact us to check on as well.”
                  <br />
                  “There are no apps, websites, or any extra things you have to
                  do. StorageDefender is a simple-to-use, state-of-the-art,
                  smart technology service that we like to refer to as your
                  personal superhero providing you
                  <b><u>24/7 PEACE OF MIND.</u></b
                  >”
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  B. Call Script for a Current Renter
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  “Just wanted to bring to your attention that we have a new
                  service available to turn your regular storage unit into a
                  <b><i>SMART UNIT</i></b
                  >.”
                  <br />
                  “A <b><i>SMART UNIT</i></b> is our
                  <u>state-of-the-art secured storage unit</u> that will alert
                  you, 24/7, if there is motion detected in your unit. Once
                  detected it will send you an alert through text asking if it
                  was you, to which you reply yes or no. If yes, then it cancels
                  the alert but if you reply no, then you can decide to take
                  action if you have a concern, stop into your unit, or contact
                  us to check on as well.”
                  <br />
                  “There are no apps, websites, or any extra things you have to
                  do. StorageDefender is a simple-to-use, state-of-the-art,
                  smart technology service that we like to refer to as your
                  personal superhero providing you
                  <b><i>24/7 PEACE OF MIND</i></b
                  >.”
                  <br />
                  “All you have to do is come by the office at your earliest
                  convenience and permit us in your unit so we can add the
                  service, all in the matter of a few minutes.”
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <b> Device Mounting Guidelines </b>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Ceiling Mounting Guidelines for Interior Building, Open-Air
                  Units
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <a href="overhead_mounting_interior.pdf" target="_blank"
                    >Procedure
                  </a>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Ceiling Mounting Guidelines for Fully-Enclosed, Drive-up Units
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <a href="overhead_mounting_driveup.pdf" target="_blank"
                    >Procedure
                  </a>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Wall Mounting Guidelines for Devices
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <a href="wall_mounting.pdf" target="_blank">Procedure </a>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <b>Common Questions</b>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  How does a Smart unit work?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  Smart units use a motion detection technology developed by
                  StorageDefender to monitor your valuables inside your storage.
                  By choosing a smart unit, you will receive automatic
                  real-time, text message alerts that will notify you if motion
                  is detected inside your unit 24/7.
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  Does my Smart unit have a camera?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  No, the smart unit uses motion detection technology to monitor
                  the space inside your unit for any motion. Lights, vibrations,
                  and wind will not falsely set off the motion detector.
                  <br />
                  And your privacy is maintained, no one will be able to see
                  your property.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Who will be sent an SMS?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  You and whoever you list as a secondary contact for your Smart
                  unit. This person is typically a family member, friend, or
                  business associate that would have a key to your unit. If you
                  don’t want anyone else to receive the alerts, that is fine as
                  well and you will be the only person to receive the alerts.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Can you explain how notifications are escalated?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-img
                    contain
                    max-height="400"
                    max-width="100%"
                    src="/assets/help/esc_tree.png"
                  />
                  <table>
                    <tbody>
                      <tr class="bbg">
                        <td>
                          <p>
                            <span>Status Indicator</span>
                          </p>
                        </td>
                        <td>
                          <p><span>When</span></p>
                        </td>
                        <td>
                          <p><span>Meaning</span></p>
                        </td>
                        <td>
                          <p>
                            <span>Subscriber Notification</span>
                          </p>
                        </td>
                        <td>
                          <p>
                            <span>Facility Notification</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p><span></span></p>
                          <p>
                            <span>
                              <img
                                src="/assets/help/happy.png"
                                style="width: 48px; height: 48px"
                              />
                            </span>
                          </p>
                        </td>
                        <td>
                          <ul>
                            <li>
                              <span
                                >Primary or Back-up Contact positively confirms
                                alert</span
                              >
                            </li>
                          </ul>
                        </td>
                        <td>
                          <p>
                            <span
                              >Motion is considered positively authorized by
                              Subscriber, thus not requiring concern</span
                            >
                          </p>
                        </td>
                        <td>
                          <p>
                            <span>Text sent that subscriber </span
                            ><span>stating</span
                            ><span
                              >&nbsp;positive acknowledgement and clearing of
                              alert</span
                            >
                          </p>
                        </td>
                        <td>
                          <p>
                            <span>Authorized Access Indexed on Dashboard</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p><span></span></p>
                          <p><span></span></p>
                          <p>
                            <span>
                              <img
                                src="/assets/help/sad.png"
                                style="width: 48px; height: 48px"
                              />
                            </span>
                          </p>
                        </td>
                        <td>
                          <ul>
                            <li>
                              <span
                                >Whenever Back-up Contact negatively responds to
                                motion alert</span
                              >
                            </li>
                            <li>
                              <span
                                >Whenever Primary Subscriber negatively responds
                                without positive response of back-up
                                contact</span
                              >
                            </li>
                          </ul>
                        </td>
                        <td>
                          <p>
                            <span
                              >Motion is considered negatively or unauthorized
                              by Subscriber, thus potentially requiring
                              concern</span
                            >
                          </p>
                        </td>
                        <td>
                          <p>
                            <span
                              >Text sent that highlights a positive
                              acknowledgement was not received and to contact
                              facility during normal hours if subscriber is
                              concerned</span
                            >
                          </p>
                        </td>
                        <td>
                          <p>
                            <span
                              >Robo Call to Facility and Unauthorized Access
                              Indexed on Dashboard</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p><span></span></p>
                          <p>
                            <span>
                              <img
                                src="/assets/help/meh.jpg"
                                style="width: 48px; height: 48px"
                              />
                            </span>
                          </p>
                        </td>
                        <td>
                          <ul>
                            <li>
                              <span
                                >Neither Primary or Back-up Contact positively
                                or negatively confirm alert</span
                              >
                            </li>
                          </ul>
                        </td>
                        <td>
                          <p>
                            <span
                              >Classification of motion is not known thus
                              assumed not a going concern</span
                            >
                          </p>
                        </td>
                        <td>
                          <p><span>N/A</span></p>
                        </td>
                        <td>
                          <p><span>N/A</span></p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  I walked in, but didn’t receive an alert?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  After the initial alert, you receive and you answer “yes,” the
                  device turns off until it no longer detects motion in your
                  unit. We do this so we don’t spam your phone every time you go
                  into your unit on the same trip. Once you leave your unit, the
                  device rearms, and your stuff is monitored.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  How do I change my phone number?
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  >You can change your phone anytime by contacting the facility
                  that your smart unit is located. Once you change your phone
                  number, you will receive an alert on both your old number and
                  the new number of the change to confirm it was done properly.
                  Please check that the information is correct.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  What happens if I say “no”?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  If you are the primary and respond “no,” we will then notify
                  by text your secondary back-up contact to see if they are at
                  the unit. If your secondary backup number responds “yes,” the
                  alert is cleared and will automatically text notify you and
                  your back-up with a message saying everything is OK. If the
                  secondary number responds “no,” we notify both parties that
                  there is potentially unauthorized access at your smart unit.
                  StrorageDefender will at that time send you the facility phone
                  number and of course, you will have the location and time of
                  the incident provided in case you should want to contact us.
                  StorageDefender will also notify us that your unit experienced
                  an unauthorized entry and we will inspect during normal
                  business hours.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Is someone monitoring my unit as a security company would?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  No, StorageDefender is an automatic smart monitoring service
                  that sends SMS upon detecting human motion and checks with you
                  responsively to see if the motion was authorized by you.
                  StorageDefender does also provide an additional after-hours,
                  audible beeper to further deter intruders. These enhanced
                  features together are purposed to provide you added, real-time
                  peace of mind.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Will the StorageDefender service automatically call the
                  police?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  No, StorageDefender Smart Units are not classified as security
                  or alarm monitoring service, which is most often staffed by
                  central monitoring personnel. Instead, you directly provide
                  personal monitoring of your valuables and StorageDefender
                  gives you real-time alerts to be able to respond yourself,
                  call the facility, or and alert the authorities if you believe
                  it is necessary. StorageDefender does also provide an
                  additional after-hours, audible beeper to further deter
                  intruders. These enhanced features together are purposed to
                  provide you added, real-time peace of mind.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Do I have to download an app on my phone? / My phone doesn’t
                  have apps.
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  No, there is no app required to use our smart units. It is as
                  simple as texting Yes or No.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Will I get false alerts?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  No, the Smart system is designed ONLY to notice human movement
                  & presence. It will however sense a friend, family, or
                  business partner you might have provided access to your unit
                  but who you did not familiarize with the StorageDefender
                  service.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  How do I sign up?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  Call or visit your local facility to get signed up today! It
                  takes less than a couple of minutes total and works with new
                  or existing customers.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  What happens when I move out?
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  >Once you have moved out of your Smart unit, let the facility
                  know and they will remove you from the system. You will
                  receive a ‘Thank you” text as well as a notification the
                  service has ended. Please let us know if you do not.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Can I ignore the alert if I know someone is there?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  We recommend that you always respond to any alert that you
                  receive so that we have everything on record in case there is
                  an incident at your unit, and StorageDefender can help you
                  with the exact time that the incident occurred.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Are smart units only in certain sizes?
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  No, Smart units are available in any size.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Demo Unit Feature
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <DemoUnitInfo />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Manual Tenant Addendum Upload
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ManualUploadInfo />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <b>Potential Customer Objections to Subscribing</b>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  This smart unit is a bit too expensive (customer younger or
                  someone perhaps digitally savvy)
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  Do you have any smart home products such as an Amazon Alexa,
                  Google Home, Ring Doorbell, or perhaps Video Cameras? We are
                  finding many people like yourself desire the same type of
                  peace and assurance they expect at home here in their remote
                  smart garage. This month we have a Smart Unit move-in special
                  that makes your 1st month free. Would you like me to write up
                  the lease now?
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  >This smart unit is a bit too expensive (customer bit older or
                  especially not tech-savvy)
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  >While we have excellent facility security coverage, our
                  state-of-the-art Smart Units provide our customers added Peace
                  of Mind as if a Superhero is watching your valuables 24/7 and
                  notifies you if your unit has any motion inside. This month we
                  have a Smart Unit move-in special that makes your 1st month
                  free. Would you like me to write up the lease now?
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  >I don’t believe the facility has a security problem, does it?
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  >No, we have not had any issues. I am just wondering if you
                  would value the added Peace of Mind this provides with a 24/7
                  Superhero watching your valuable– who notifies you if your
                  unit has any motion inside. This month we have a Smart Unit
                  move-in special that makes your 1st month free. Would you like
                  me to write up the lease now?
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  >“I don’t know if I need a smart unit” (meaning, it is not
                  about the price)
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  >Do you have any smart home products such as the Amazon Alexa,
                  Google Home, Ring Doorbell, or perhaps Smart Lighting? We are
                  finding many people like yourself desire the same type of
                  peace and assurance they expect at home here in their remote
                  smart garage. This month we have a Smart Unit move-in special
                  that makes your 1st month free. Would you like me to write up
                  the lease now?
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  >“This sounds complicated, I don’t want to be bothered”
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  >It’s really easy to use as it works by simple text messages
                  only when motion is noticed in your unit. (If at the counter)
                  Look here on my Phone what the text looks like (in the future
                  we will have a demo button to push a text). No App is
                  required. This month we have a Smart Unit move-in special that
                  makes your 1st month free. Would you like me to write up the
                  lease now?
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  >“This smart unit is far too expensive” (customer indicates
                  you are $15 to $20 too high)
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  >Are you aware of our Smart Unit move-in special this month
                  making your unit free for the 1st 30 days? Ok, you are still
                  not interested, No problem, I see we have another 10 x 10 unit
                  on the 1st floor that is not a smart unit I can show you as
                  well.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-sheet>
</template>

<script>
import YoutubeVideos from "./YoutubeVideos.vue";
import DemoUnitInfo from "./DemoUnitInfo.vue";
import ManualUploadInfo from "./ManualUploadInfo.vue";

export default {
  name: "PageHelp",
  components: { YoutubeVideos, DemoUnitInfo, ManualUploadInfo },
};
</script>

<style lang="scss" scoped>
.highlight {
  padding: 0.2em;
  font-weight: bold;
  text-decoration: underline;
}

ul {
  padding-bottom: 1em;

  li {
    margin: 0 0 0 1em;
    padding: 0.25em 0em;
  }
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}

.bbg {
  background-color: #b4c6e7;
}
</style>

import PageMonitorAndControlHub from "./PageMonitorAndControlHub.vue";
import PageIncidentDispositionReport from "./PageIncidentDispositionReport.vue";
import PageIncident from "@components/incident/PageIncident.vue";
const routes = {
  navigation: [
    {
      path: "/monitor_and_control_hub",
      name: "MonitorAndControlHubDashboard",
      component: PageMonitorAndControlHub,
    },
    // {
    //   path: "/monitor_and_control_hub/incident-info/:id",
    //   name: "ControlHubIncidentInfo",
    //   component: PageIncidentInfo,
    // },
    {
      path: "/organization/:organization_id/incident/:incident_id",
      name: "PageIncidentInfo",
      component: PageIncident,
    },
    {
      path: "/monitor_and_control_hub/incident-disposition-report",
      name: "PageIncidentDispositionReport",
      component: PageIncidentDispositionReport,
    },
  ],
};

export default routes;

<template>
  <LayoutPage title="Escalation Matrix">
    <template #actions>
      <v-btn
        class="action-btn"
        :color="tab === 0 ? 'success' : 'grey lighten-1'"
        @click="tab = 0"
      >
        Priority Settings
      </v-btn>
      <v-btn
        class="action-btn"
        :color="tab === 1 ? 'success' : 'grey lighten-1'"
        @click="tab = 1"
      >
        Incident Escalation Matrix
      </v-btn>
      <v-spacer />
      <v-spacer />
    </template>

    <div v-if="tab === 0" class="mt-4">
      <div
        v-for="priority in priorities"
        :key="priority.name"
        class="accordion-item"
      >
        <v-card
          flat
          :class="{ 'mb-2': true, 'accordion-active': priority.isOpen }"
          @click="toggleAccordion(priority.name)"
        >
          <v-card-title
            class="accordion-header d-flex justify-space-between"
            :style="{ color: getColorBySeverity(priority.name) }"
          >
            {{ priority.title }}
            <v-icon>
              {{ priority.isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
          </v-card-title>
        </v-card>

        <v-expand-transition>
          <div v-if="priority.isOpen" class="accordion-content">
            <v-card flat>
              <v-card-text>
                <CardPriorityContent
                  :name="priority.name"
                  :level="priority.level"
                  :settings="getSettingsForPriority(priority.name)"
                  :all-priority-data="priorityData"
                  :feature-id="priorityFeatureId"
                  :field-disabled="fieldDisabled"
                  @save-success="handleSaveSuccess"
                  @save-error="handleSaveError"
                  @view-assigned-incidents="handleViewAssignedIncidents"
                />
              </v-card-text>
            </v-card>
          </div>
        </v-expand-transition>
      </div>
    </div>

    <div v-else-if="tab === 1" class="mt-4">
      <CardIncidentEscalationMatrix
        :initial-sensor="selectedIncidentType"
        :initial-priority="selectedPriorityLevel"
        :priority-matrix="priorityMatrix"
        :feature-id="escalationFeatureId"
        :field-disabled="fieldDisabled"
      />
    </div>
  </LayoutPage>
</template>

<script>
import CardPriorityContent from "./CardPriorityContent.vue";
import CardIncidentEscalationMatrix from "./CardIncidentEscalationMatrix.vue";
import { GET_OVERRIDDEN_ORGANIZATION_FEATURE } from "./graphql";
import { ORGANIZATION_FEATURES } from "../../graphql/organizations";
import useAlerts from "@tod-ui/composables/useAlerts";
import LayoutPage from "@layout/LayoutPage.vue";
import { getColorBySeverity } from "../dashboard/helper";
import useUser from "../authentication/useUser";

export default {
  name: "PageEscalationMatrix",
  components: {
    CardPriorityContent,
    LayoutPage,
    CardIncidentEscalationMatrix,
  },
  setup() {
    const { defaultOrganization } = useUser();
    const { addAlert } = useAlerts();
    return {
      addAlert,
      defaultOrganization,
    };
  },
  data() {
    return {
      tab: 0,
      priorities: [],
      priorityData: null,
      loading: false,
      selectedIncidentType: null,
      selectedPriorityLevel: null,
      priorityFeatureId: null,
      escalationFeatureId: null,
    };
  },

  computed: {
    fieldDisabled() {
      return this.defaultOrganization.type !== "region";
    },
  },

  apollo: {
    organizationFeatures: {
      query: ORGANIZATION_FEATURES,
      variables() {
        return {
          id: this.defaultOrganization.id,
        };
      },
      fetchPolicy: "no-cache",
      update(data) {
        try {
          const organization = data.organization;
          const priorityFeature = organization.features.find(
            (f) => f.feature.name === "incident_priority_matrix"
          );
          const escalationFeature = organization.features.find(
            (f) => f.feature.name === "incident_escalation_matrix"
          );

          if (priorityFeature) {
            this.priorityFeatureId = priorityFeature.id;
          }
          if (escalationFeature) {
            this.escalationFeatureId = escalationFeature.id;
          }

          return organization.features;
        } catch (error) {
          console.error("Error processing organization features:", error);
          return [];
        }
      },
      error(error) {
        console.error("Error fetching organization features:", error);
      },
    },

    priorityMatrix: {
      query: GET_OVERRIDDEN_ORGANIZATION_FEATURE,
      variables() {
        return {
          featureName: "incident_priority_matrix",
          organizationId: this.defaultOrganization.id,
        };
      },
      fetchPolicy: "network-only",

      update(data) {
        const matrixData =
          data.overridenOrganizationFeature.config.incidentPriorityMatrix;
        this.priorityData = matrixData;

        this.priorities = matrixData.map((item) => ({
          level: item.level,
          name: item.name,
          title: item.name.charAt(0).toUpperCase() + item.name.slice(1),
          isOpen: false,
        }));

        return matrixData;
      },
      error(error) {
        console.error("Error fetching data:", error);
        this.addAlert({
          type: "error",
          message: "Error fetching priority settings.",
          timeout: 10,
        });
      },
    },
  },

  methods: {
    toggleAccordion(name) {
      this.priorities = this.priorities.map((priority) => ({
        ...priority,
        isOpen: priority.name === name ? !priority.isOpen : false,
      }));
    },
    handleViewAssignedIncidents(incidentType, priorityLevel) {
      this.selectedIncidentType = incidentType.toLowerCase();
      this.selectedPriorityLevel = priorityLevel.toLowerCase();
      this.tab = 1;
    },
    getSettingsForPriority(name) {
      if (!this.priorityData) return {};
      return this.priorityData.find((item) => item.name === name) || {};
    },

    async handleSaveSuccess() {
      this.addAlert({
        type: "success",
        message: "Priority settings saved successfully",
        timeout: 5,
      });
      try {
        await this.$apollo.queries.priorityMatrix.refetch();
      } catch (error) {
        console.error("Error refetching data:", error);
      }
    },

    handleSaveError() {
      this.addAlert({
        type: "error",
        message: "Error occurred while saving priority settings.",
        timeout: 5,
      });
    },
    getColorBySeverity,
  },
};
</script>

<style scoped>
.accordion-header {
  cursor: pointer;
  background-color: #f5f5f5;
  padding: 12px 16px;
  border-radius: 4px;
}

.accordion-content {
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-top: none;
  margin-bottom: 8px;
}

.v-card {
  box-shadow: none !important;
}
</style>

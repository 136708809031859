<template>
  <v-autocomplete
    v-model="selectedIncident"
    :items="incidents"
    label="Search for an incident"
    placeholder="Search for an incident"
    item-text="shortId"
    item-value="id"
    return-object
    prepend-icon="mdi-magnify"
    :search-input.sync="searchInput"
    clearable
    v-bind="$attrs"
    v-on="$listeners"
    @change="handleSelection"
  >
    <template #selection="{ item }">
      <span class="ml-2">{{ item.shortId }}</span>
    </template>
    <template #item="{ item }">
      <v-list-item-icon class="mr-1">
        <IconIncidentType :incident="item" class="mr-1" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.shortId }}-{{ item.incidentType }}
        </v-list-item-title>
        <v-list-item-subtitle>
          Smart Unit/Zone: {{ item.zoneName }} on
          {{ facilityFormatDateTime4Humans(item.startedAt) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import useUser from "@components/authentication/useUser";
import useFacility from "../authentication/useFacility";
import IconIncidentType from "@atoms/IconIncidentType.vue";
import { incidentStatus, formatIncidentType } from "../common/helpers/incident";
import { INCIDENT_HISTORY } from "@components/reports/graphql";
import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import { diff } from "@tod-ui/helpers/datetime";
import { ref, watch, onMounted, nextTick } from "vue";

export default {
  components: {
    IconIncidentType,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    defaultValue: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { defaultOrganization, isFacilityManager } = useUser();

    const { facilityFormatDateTime4Humans } = useFacility();

    const searchInput = ref("");
    const selectedIncident = ref(null);
    const incidents = ref([]);
    const search = ref("");
    const noDataText = ref("Type in a incident code");

    // Watch searchInput and update search only when typing
    watch(searchInput, (newValue) => {
      if (newValue !== selectedIncident.value?.shortId) {
        search.value = newValue;
      }
    });

    const handleSelection = () => {
      // Clear searchInput when an item is selected
      searchInput.value = "";
      emit("input", selectedIncident.value.id);
      emit("incident", selectedIncident.value);
    };
    // Preload defaultValue if provided
    const preloadDefaultValue = async () => {
      if (props.defaultValue) {
        search.value = props.defaultValue.split("-")[0];
        await nextTick();
        const matchingIncident = incidents.value.find((incident) =>
          incident.shortId.includes(props.defaultValue)
        );
        if (matchingIncident) {
          selectedIncident.value = matchingIncident;
          emit("input", matchingIncident.id);
          emit("incident", matchingIncident);
        }
      }
    };
    onMounted(() => {
      preloadDefaultValue();
    });

    return {
      defaultOrganization,
      searchInput,
      selectedIncident,
      incidents,
      search,
      handleSelection,
      isFacilityManager,
      noDataText,
      facilityFormatDateTime4Humans,
    };
  },
  apollo: {
    incidents: {
      query: INCIDENT_HISTORY,
      variables() {
        return {
          parentId: this.defaultOrganization.id,
          searchText: this.searchInput || this.search,
        };
      },
      skip() {
        return !this.search;
      },
      update({ incidents }) {
        const updatedIncidents = incidents.map((incident) => {
          const responder = incident.responders?.length
            ? incident.responders.sort(
                (a, b) => a.escalationLevel - b.escalationLevel
              )[0]
            : "--";

          const endedAt =
            incident.lastUntriggeredAt ||
            incident.closedAt ||
            incident.resolvedAt;
          return {
            ...incident,
            zoneName: incident.organization.name,
            incidentType: formatIncidentType(incident),
            responder: formatResponder(responder),
            startedAt: incident.startedAt,
            endedAt,
            duration: diff(incident.startedAt, endedAt).as("minutes"),
            statusText: incidentStatus(incident),
          };
        });
        this.incidents = updatedIncidents;
        // If we have a default value it will select matching incident
        if (this.defaultValue && !this.selectedIncident) {
          const matchingIncident = updatedIncidents.find((incident) =>
            incident.shortId.includes(this.defaultValue)
          );

          if (matchingIncident) {
            this.selectedIncident = matchingIncident;
            this.$emit("input", matchingIncident.id);
            this.$emit("incident", matchingIncident);
          }
        }
        return updatedIncidents;
      },
      debounce: 300,
    },
  },
};
function formatResponder(responder) {
  if (!responder?.name) return "--";
  if (!responder?.contactMethod?.value) return responder.name;
  return `${responder.name} @ ${formatPhoneNumber(
    responder.contactMethod.value
  )}`;
}
</script>

<style lang="scss" scoped></style>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="bordered-box my-3">
          <v-row class="align-center">
            <v-col cols="2" class="text-center">
              <v-icon
                class="fab fa-slack"
                color="#4A154B"
                style="font-size: 100px"
              />
            </v-col>

            <v-col cols="7">
              <div>
                <strong class="text-h6">Slack Integration</strong>
              </div>
              <div class="subtitle-2 text-grey">by StorageDefender</div>
            </v-col>

            <v-col v-if="!isSlackEnabled" cols="3" class="text-center">
              <v-btn color="info" class="mb-2" @click="authenticate">
                Connect with Slack
              </v-btn>
              <div class="text-subtitle-2">Connect Securely</div>
              <div class="text-caption mt-1">
                <a href="https://slack.com" target="_blank"
                  >Slack Official Website</a
                >
              </div>
            </v-col>
            <v-col v-else cols="3" class="text-center">
              <div class="text-subtitle-2">
                <v-btn color="info" class="mb-2" @click="authenticate">
                  RE-CONNECT TO SLACK
                </v-btn>
                <div class="text-subtitle-2" style="color: success">
                  Securely Connected
                </div>
                <div class="text-caption mt-1">
                  <a href="https://slack.com" target="_blank"
                    >Slack Official Website</a
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import useUser from "../authentication/useUser";
import { ref, onMounted, nextTick, computed } from "vue";
import { GET_SLACK_CHANNELS } from "../authentication/graphql";

export default {
  name: "PageSlack",
  setup() {
    const { defaultOrganization } = useUser();
    const currentOrganizationId = ref(defaultOrganization?.value?.id);
    return { currentOrganizationId };
  },
  data() {
    return {
      slackChannels: [],
    };
  },
  computed: {
    isSlackEnabled() {
      return this.slackChannels.length > 0;
    },
  },
  apollo: {
    slackChannels: {
      query: GET_SLACK_CHANNELS,
      variables() {
        return {
          organizationId: this.currentOrganizationId,
        };
      },
      update(data) {
        return (this.slackChannels = data?.slackChannels || []);
      },
      skip() {
        return !this.currentOrganizationId;
      },
    },
  },
  methods: {
    authenticate() {
      const authUrl =
        import.meta.env.VUE_APP_SLACK_AUTH_URL ||
        "http://127.0.0.1:4000/slack/slack-auth";
      const urlWithParams = `${authUrl}?organization_id=${encodeURIComponent(
        this.currentOrganizationId
      )}`;
      window.open(urlWithParams, "_blank");
    },
  },
};
</script>

<style scoped>
.bordered-box {
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 4px;
}

.bordered-search {
  border-bottom: 3px solid lightblue;
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 16px;
}
</style>

<template>
  <v-card
    class="mb-2"
    outlined
    :class="{ 'selected-card': isSelected }"
    @click="handleCardClick"
  >
    <v-card-text class="d-flex align-center">
      <div class="flex-grow-1">
        <div class="text-subtitle-2 mb-1">{{ zone.zoneName }}</div>
        <div class="text-body-2 text-secondary">
          @ {{ zone.facilityName }}
          <template v-if="zone.organization.location?.spots">
            | {{ zone.organization.location.spots.join(", ") }}
          </template>
        </div>
      </div>

      <div class="text-right">
        <div class="d-flex align-center">
          <div class="text-h6 mr-2" :class="colorClass">
            {{ formattedValue }}
          </div>
          <component :is="iconComponent" v-bind="iconProps" ref="iconRef" />
        </div>
        <div class="text-body-2 text-secondary">
          {{ zone.organization.incidents.length }} incidents
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { computed } from "vue";
import IconThermometer from "@atoms/IconThermometer.vue";
import IconHumidity from "@atoms/IconHumidity.vue";
import { convertCelsiusToFahrenheit } from "@tod-ui/helpers/utils";
import { roundValue } from "@tod-ui/helpers/math";
import { ref } from "vue";
import {
  smartUnitHumidityColor,
  smartUnitTemperatureColor,
} from "@components/smart_units/helper";

export default {
  name: "ZoneClimateCard",

  components: {
    IconThermometer,
    IconHumidity,
  },

  props: {
    zone: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (value) =>
        ["TEMPERATURE", "HUMIDITY"].includes(value.toUpperCase()),
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["select"],

  setup(props, { emit }) {
    const iconRef = ref(null);
    const isTemperature = computed(
      () => props.type.toUpperCase() === "TEMPERATURE"
    );

    const formattedValue = computed(() => {
      if (isTemperature.value) {
        return `${convertCelsiusToFahrenheit(props.zone.climateReading)}°F`;
      }
      return `${roundValue(props.zone.climateReading)}%`;
    });

    const colorClass = computed(() => {
      // Directly use the icon's color class
      if (iconRef.value) {
        return iconRef.value.$el.className
          .split(" ")
          .find((cls) => cls.includes("--text") || cls.includes("color-"));
      }
      return "";
    });

    const iconComponent = computed(() =>
      isTemperature.value ? IconThermometer : IconHumidity
    );

    const iconProps = computed(() => {
      if (isTemperature.value) {
        return {
          temperatureInCelsius: props.zone.climateReading,
          lowThresholdInCelsius:
            props.zone.organization?.profile?.climateConfig
              ?.lowTemperatureThreshold,
          highThresholdInCelsius:
            props.zone.organization?.profile?.climateConfig
              ?.highTemperatureThreshold,
        };
      }
      return {
        humidity: props.zone.climateReading,
        lowThreshold:
          props.zone.organization?.profile?.climateConfig?.lowHumidityThreshold,
        highThreshold:
          props.zone.organization?.profile?.climateConfig
            ?.highHumidityThreshold,
      };
    });

    const handleCardClick = () => {
      emit("select", props.zone);
    };

    return {
      formattedValue,
      iconComponent,
      iconProps,
      handleCardClick,
      colorClass,
      iconRef,
    };
  },
};
</script>

<style scoped>
.selected-card {
  border: 2px solid var(--v-primary-base) !important;
  background-color: #d8dce9 !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
  transform: translateY(-1px);
  transition: all 0.2s ease;
}

.v-card {
  transition: all 0.2s ease;
}

.v-card:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
}
</style>

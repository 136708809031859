<template>
  <v-skeleton-loader :loading="loading" type="card">
    <v-card
      style="min-height: 130px; max-height: 190px"
      outlined
      class="black-border"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="d-flex flex-column align-center">
            <div v-if="heading" class="mb-3">{{ heading }}</div>
            <div
              class="text-h5 mb-2"
              :class="{ 'cursor-pointer': isClickable }"
              @click="emitHeadClickEvent"
            >
              {{ headValue }}
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <div class="d-flex flex-column align-center">
              <p v-if="topTitle" class="mb-2">
                {{ topTitle }}
                <v-tooltip v-if="topTooltip" bottom color="black">
                  <template #activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon small>mdi-information</v-icon>
                    </span>
                  </template>
                  <span>{{ topTooltip }}</span>
                </v-tooltip>
              </p>
              <div :class="arrowAlignClass">
                <div
                  v-if="topValue"
                  :style="{ color: topColor }"
                  class="text-h5 mb-2"
                  :class="{ 'cursor-pointer': isClickable }"
                  @click="emitTopClickEvent"
                >
                  {{ topValueWithUnit }}
                </div>
              </div>
              <span v-if="topValueChange" class="ml-3">
                <!-- <IconArrow
                  :color="topValueChange === 'up' ? '#2AAA8A' : '#FB9E85'"
                  :direction="topValueChange"
                >
                  <span v-if="topValueChangePercent">
                    {{ topValueChangePercent }}%
                  </span>
                </IconArrow> -->
              </span>
            </div>

            <div class="d-flex flex-column align-center">
              <div v-if="bottomTitle" class="mb-2">
                {{ bottomTitle }}
                <v-tooltip v-if="bottomTooltip" bottom color="black">
                  <template #activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-icon small>mdi-information</v-icon>
                    </span>
                  </template>
                  <span>{{ bottomTooltip }}</span>
                </v-tooltip>
              </div>
              <div :class="arrowAlignClass" @click="emitBottomClickEvent">
                <div
                  v-if="bottomValue"
                  class="text-h5"
                  :class="{ 'cursor-pointer': isClickable }"
                  :style="{ color: bottomColor }"
                >
                  {{ bottomValueWithUnit }}
                </div>
                <span v-if="bottomValueChange" class="ml-3">
                  <!-- <IconArrow
                    :color="bottomValueChange === 'up' ? '#2AAA8A' : '#FB9E85'"
                    :direction="bottomValueChange"
                  /> -->
                  <span v-if="bottomValueChangePercent">
                    {{ bottomValueChangePercent }}%
                  </span>
                </span>
              </div>
            </div>
          </div>
        </v-list-item-content>
        <v-list-item-avatar v-if="avatarColor" size="70" :color="avatarColor">
          <v-icon v-if="avatarName === 'open'" color="white" size="40">
            mdi-open-source-initiative
          </v-icon>
          <v-icon v-if="avatarName === 'inprogress'" color="white" size="40"
            >mdi-progress-alert</v-icon
          >
          <v-icon v-if="avatarName === 'task'" color="white" size="40"
            >mdi-calendar-check</v-icon
          >
        </v-list-item-avatar>
      </v-list-item>
      <div
        v-if="bottomTitle"
        :style="{ height: '10px', backgroundColor: avatarColor }"
      ></div>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
export default {
  name: "CardComparisonTile",

  props: {
    heading: {
      type: String,
      default: "",
    },
    headValue: {
      type: String,
      default: "",
    },
    bottomColor: {
      type: String,
      default: "",
    },
    topColor: {
      type: String,
      default: "",
    },
    isShadow: {
      type: Boolean,
      default: true,
    },
    topTitle: {
      type: String,
      default: "",
    },
    bottomTitle: {
      type: String,
      default: "",
    },
    topTooltip: {
      type: String,
      default: "",
    },
    bottomTooltip: {
      type: String,
      default: "",
    },
    topValue: {
      type: [String, Number],
      default: "",
    },
    bottomValue: {
      type: [String, Number],
      default: "",
    },
    topUnit: {
      type: String,
      default: "",
    },
    bottomUnit: {
      type: String,
      default: "",
    },
    topValueChange: {
      type: String,
      default: "",
    },
    topValueChangePercent: {
      type: [String, Number],
      default: "",
    },
    bottomValueChange: {
      type: String,
      default: "",
    },
    bottomValueChangePercent: {
      type: [String, Number],
      default: "",
    },
    topClickEvent: {
      type: String,
      default: "",
    },
    bottomClickEvent: {
      type: String,
      default: "",
    },
    avatarColor: {
      type: String,
      default: "",
    },
    avatarName: {
      type: String,
      default: "",
    },
    isClickable: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
  computed: {
    topValueWithUnit() {
      return this.topUnit === "$"
        ? `${this.topUnit} ${this.topValue}`
        : `${this.topValue} ${this.topUnit}`;
    },
    bottomValueWithUnit() {
      return this.bottomUnit === "$"
        ? `${this.bottomUnit} ${this.bottomValue}`
        : `${this.bottomValue} ${this.bottomUnit}`;
    },
    arrowAlignClass() {
      return this.topValueChangePercent
        ? "arrowAlign-column"
        : "arrowAlign-row";
    },
  },
  methods: {
    emitHeadClickEvent() {
      let value = this.heading ? this.heading : this.heading;
      value = value.replace(/\s/g, "").toLowerCase();
      this.$emit("headClickEvent", value);
    },
    emitTopClickEvent() {
      let value = this.topTitle ? this.topTitle : this.heading;
      if (this.heading === "Open Tasks") {
        value = "opentaskcurrent";
      } else if (this.heading === "Open Incidents") {
        value = "openincidentcurrent";
      } else if (this.heading === "In Progress Incidents") {
        value = "inreview";
      }
      value = value.replace(/\s/g, "").toLowerCase();
      this.$emit("topClickEvent", value);
    },
    emitBottomClickEvent() {
      let value = this.bottomTitle ? this.bottomTitle : this.heading;
      if (this.heading === "Open Tasks") {
        value = "opentaskoverdue";
      } else if (this.heading === "Open Incidents") {
        value = "openincidentoverdue";
      } else if (this.heading === "In Progress Incidents") {
        value = "overdueinprogressincidents";
      }
      value = value.replace(/\s/g, "").toLowerCase();
      this.$emit("bottomClickEvent", value);
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.tile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrowAlign-column {
  display: flex;
  /* flex-direction: column-reverse;   */
}

.arrowAlign-row {
  display: flex;
}

/* .gradient {
    height: 12px;
    background-color: #d50d41;
    background-image: linear-gradient(160deg, #d50d41 0%, #323487 100%);
  } */
</style>

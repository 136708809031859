<template>
  <LayoutPage :loading="loading" :title="incidentTitle">
    <template v-if="!needsSurvey" #actions>
      <ButtonPrint :icon="false" color="grey darken-2" />
    </template>

    <v-row>
      <v-col v-if="needsSurvey" md="8">
        <FormIncidentSurvey />
      </v-col>

      <v-col cols="12" class="mx-auto print-full-width">
        <CardIncidentInfo :single-column="needsSurvey" />
      </v-col>
      <v-col cols="12">
        <div class="d-flex justify-end mb-2">
          <AddNewTask :default-value="defaultValue" />
        </div>
        <CardIncidentTasks />
      </v-col>
      <v-col v-if="surveyed" cols="12" class="print-full-width">
        <CardIncidentReport />
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import { computed, onUnmounted } from "vue";
import LayoutPage from "@layout/LayoutPage.vue";
import CardIncidentInfo from "./CardIncidentInfo.vue";
import CardIncidentReport from "./CardIncidentReport.vue";
import FormIncidentSurvey from "./FormIncidentSurvey.vue";
import useIncident from "./useIncident";
import ButtonPrint from "@atoms/ButtonPrint.vue";
import CardIncidentTasks from "./CardIncidentTasks.vue";
import AddNewTask from "@components/manage_tasks/AddNewTask.vue";
export default {
  name: "PageIncident",
  components: {
    LayoutPage,
    CardIncidentInfo,
    FormIncidentSurvey,
    CardIncidentReport,
    CardIncidentTasks,
    ButtonPrint,
    AddNewTask,
  },
  setup() {
    const {
      incident,
      incidentTitle,
      loading,
      fetchIncident,
      cleanupIncidentRef,
    } = useIncident();

    const needsSurvey = computed(() => incident.value?.needsSurvey);
    const surveyed = computed(() => incident.value?.surveyed);
    const defaultValue = computed(() => {
      return {
        unit:
          incidentTitle.value[1].split(" ")[0] +
          " - " +
          incident.value?.facilityName.split(" ")[0],
        taskType: "incident_resolution",
        incidentId: incident.value?.shortId,
      };
    });
    onUnmounted(cleanupIncidentRef);
    return {
      incidentTitle,
      loading,
      needsSurvey,
      surveyed,
      fetchIncident,
      defaultValue,
    };
  },
  computed: {
    incidentId() {
      return this.$route.params.incident_id;
    },
  },
  beforeMount() {
    this.fetchIncident(this.$apollo, this.incidentId);
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  margin: 0px;
  padding: 0px;

  li {
    list-style: none;
    padding: 0 0 0.35em 0;
  }
}
</style>

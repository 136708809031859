<template>
  <LayoutPage :title="`HBS (Human Behavior Summary)`">
    <v-row>
      <v-col cols="12" md="8">
        <RegionAndFacilitySelect />
      </v-col>
      <v-col cols="12" md="4">
        <v-row>
          <v-col>
            <v-select
              v-model="selectedStatus"
              :items="statusOptions"
              label="Status"
              item-text="name"
              item-id="value"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <CardLayout
      v-model="tableState.search"
      :title="`${reportDate} - ${titleCase(selectedStatus)}`"
      header-type="search"
    >
      <v-skeleton-loader
        :loading="incidentsLoading"
        type="table-thead, table-row-divider@10"
      >
        <v-data-table
          :headers="headers"
          :items="incidents"
          :custom-filter="customFilter"
          :custom-sort="customSort"
          :search="tableState.search"
          :sort-by.sync="tableState.sortBy"
          :sort-desc.sync="tableState.sortDesc"
          :page.sync="tableState.page"
          :items-per-page.sync="tableState.itemsPerPage"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
        >
          <template #item.actions="{ item }">
            <ButtonView @click="gotoIncident(item.id)" />
          </template>

          <template #item.incidentType="{ item }">
            <IconIncidentType :incident="item" class="mr-2" />
            {{ item.incidentType }}
          </template>

          <template #item.startedAt="{ item }">
            {{ facilityFormatDateTime4Humans(item.startedAt) }}
          </template>

          <template #item.duration="{ item }">
            {{ durationInWords(item.startedAt, item.endedAt) }}
          </template>

          <template #item.statusText="{ item }">
            <IconIncidentStatus left small :incident="item" />
          </template>

          <template #footer.prepend>
            <ButtonExportCSV
              class="order-last"
              :table-data="incidents"
              :headers="headers"
              filename="SD_Incident_History"
            />
            <ButtonPrint
              class="order-last"
              :before-print="showAllItems"
              :after-print="restoreItemsPerPage"
            />
          </template>
        </v-data-table>
      </v-skeleton-loader>
    </CardLayout>
  </LayoutPage>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import IconIncidentStatus from "@atoms/IconIncidentStatus.vue";
import ButtonView from "@atoms/ButtonView.vue";
import IconIncidentType from "@atoms/IconIncidentType.vue";
import ButtonPrint from "@atoms/ButtonPrint.vue";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";

import { INCIDENT_HISTORY } from "../../../components/reports/graphql";
import { diff, durationInWords, toISOLocal } from "@tod-ui/helpers/datetime";
import {
  incidentStatus,
  formatIncidentType,
} from "../../common/helpers/incident";
import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import useDataTable from "../../common/useDataTable";
import useFacility from "../../authentication/useFacility";
import { stringSortCollator } from "@tod-ui/helpers/strings";
import useUser from "../../authentication/useUser";
import { DateTime } from "luxon";
import LayoutPage from "@layout/LayoutPage.vue";
import useDashboard from "../useDashboard";
import RegionAndFacilitySelect from "../RegionAndFacilitySelect.vue";
import { titleCase } from "@tod-ui/helpers/strings";
import { ref } from "vue";
import { useRouter } from "vue-router/composables";
export default {
  name: "PageHumanBehaviorSummaryReport",
  components: {
    CardLayout,
    LayoutPage,
    RegionAndFacilitySelect,
    IconIncidentStatus,
    ButtonView,
    IconIncidentType,
    ButtonPrint,
    ButtonExportCSV,
  },
  setup() {
    const router = useRouter();
    const { defaultOrganization } = useUser();
    const { selectedFacilities } = useDashboard();
    const reportDate = "";
    const statusFromURL = "";
    const {
      currentFacilityId,
      facilityFeatureEnabled,
      facilityTimeZone,
      facilityFormatDateTime4Humans,
      facilityFormatDateTime,
    } = useFacility();
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
      sortBy: ["startedAt"],
      sortDesc: [true],
    });
    const selectedStatus = ref(
      decodeURIComponent(router.currentRoute.params.label || "")
    );
    return {
      defaultOrganization,
      selectedStatus,
      selectedFacilities,
      currentFacilityId,
      facilityFeatureEnabled,
      facilityTimeZone,
      facilityFormatDateTime4Humans,
      facilityFormatDateTime,
      tableState,
      reportDate,
      statusFromURL,
      showAllItems,
      restoreItemsPerPage,
    };
  },
  data() {
    return {
      dateRange: { after: undefined, before: undefined },
      currentStatus: this.selectedStatus,
      statusOptions: [
        { name: "Authorized", value: "authorized" },
        { name: "Unauthorized", value: "unauthorized" },
        { name: "Not Acknowledged", value: "not_acknowledged" },
        { name: "After Hours - Vacant", value: "after_hours_vacant" },
        { name: "After Hours - Occupied", value: "after_hours_occupied" },
      ],
      incidents: [],
      filter: {},
    };
  },
  computed: {
    apolloVariables() {
      return {
        parentId: this.defaultOrganization.id,
        dateTimeFilter: this.dateRange,
        organizationSubtypes: ["unit"],
        ...this.filter,
      };
    },
    incidentsLoading() {
      return this.$apollo.queries.incidents.loading;
    },
    headers() {
      const formatDateTime = this.facilityFormatDateTime;
      const zoneHeader = this.facilityFeatureEnabled("smart_zones")
        ? "Smart Unit/Zone"
        : "Smart Unit #";
      return [
        {
          text: "Details",
          align: "start",
          filterable: false,
          sortable: false,
          class: "action-column",
          cellClass: "action-column",
          value: "actions",
          width: "1%",
        },
        { text: zoneHeader, value: "zoneName" },
        { text: "Type", value: "incidentType" },
        { text: "Renter", value: "responder" },
        {
          text: "Started At",
          value: "startedAt",
          toString: formatDateTime,
        },
        {
          text: "Duration",
          value: "duration",
          toString: (duration) => (duration < 1 ? 1 : Math.round(duration)),
        },
        { text: "Status", value: "statusText" },
      ];
    },
  },
  apollo: {
    incidents: {
      query: INCIDENT_HISTORY,
      fetchPolicy: "network-only",
      variables() {
        return this.apolloVariables;
      },
      skip() {
        return !this.dateRange.after || !this.dateRange.before;
      },
      update({ incidents }) {
        return incidents
          .filter((incident) => {
            return incident.organization.ancestors.some((ancestor) =>
              this.selectedFacilities.some(
                (facility) => facility.id === ancestor.id
              )
            );
          })
          .map((incident) => {
            const responder = incident.responders?.length
              ? incident.responders.sort(
                  (a, b) => a.escalationLevel - b.escalationLevel
                )[0]
              : "--";

            const endedAt =
              incident.lastUntriggeredAt ||
              incident.closedAt ||
              incident.resolvedAt;

            return {
              ...incident,
              zoneName: incident.organization.name,
              incidentType: formatIncidentType(incident),
              responder: formatResponder(responder),
              startedAt: incident.startedAt,
              endedAt,
              duration: diff(incident.startedAt, endedAt).as("minutes"),
              statusText: incidentStatus(incident),
            };
          });
      },
    },
  },
  watch: {
    selectedStatus: {
      handler(newStatus) {
        if (newStatus !== this.currentStatus) {
          this.currentStatus = newStatus;
          this.filter = this.getFilterSettings(newStatus);
        }
      },
      immediate: true,
    },
    selectedFacilities: {
      handler() {
        this.$apollo.queries.incidents.refetch();
      },
    },
  },
  mounted() {
    this.setDateRangeFromUrl();
    this.setSelectedStatusFromUrl();
  },
  methods: {
    setSelectedStatusFromUrl() {
      const statusFromURL = decodeURIComponent(this.$route.params.label || "");

      const selectedOption = this.statusOptions.find(
        (option) => option.name === statusFromURL
      );
      if (selectedOption) {
        this.selectedStatus = selectedOption.value;
      }
    },
    setDateRangeFromUrl() {
      const dateParam = this.$route.params.date;
      this.statusFromURL = decodeURIComponent(
        window.location.pathname.split("/").pop()
      );

      if (dateParam) {
        const startOfDay = DateTime.fromISO(dateParam)
          .setZone(this.facilityTimeZone)
          .startOf("day")
          .toUTC()
          .toISO();
        const endOfDay = DateTime.fromISO(dateParam)
          .setZone(this.facilityTimeZone)
          .endOf("day")
          .toUTC()
          .toISO();
        this.dateRange = {
          after: startOfDay,
          before: endOfDay,
        };
        this.reportDate = DateTime.fromISO(endOfDay).toFormat("MM/dd/yyyy");
      }
    },

    getFilterSettings(status) {
      switch (status) {
        case "authorized":
          return { resolutions: ["claimed"] };
        case "unauthorized":
          return { resolutions: ["denied", "surveyed"] };
        case "not_acknowledged":
          return { resolutions: ["unexplained"] };
        case "after_hours_vacant":
          return { duringAccessHours: false, assigned: false };
        case "after_hours_occupied":
          return { duringAccessHours: false, assigned: true };
        default:
          return {};
      }
    },

    customFilter(value, search, item) {
      if (!search) return true;
      const _search = search.toLowerCase();
      const match = [
        item.zoneName.toLowerCase(),
        item.type,
        item.responder.toLowerCase(),
        this.facilityFormatDateTime4Humans(item.startedAt),
      ].join(" ");

      return match.indexOf(_search) >= 0;
    },
    customSort(items, sortBy, sortDesc) {
      const _sortBy = sortBy[0];
      const _sortDesc = sortDesc[0] ? -1 : 1;

      items.sort((a, b) => {
        if (_sortBy === "statusText") {
          const aStatus = `${a.status} ${a.resolution}`;
          const bStatus = `${b.status} ${b.resolution}`;

          return (bStatus < aStatus ? -1 : 1) * _sortDesc;
        } else {
          if (typeof a[_sortBy] === "string") {
            return (
              stringSortCollator.compare(a[_sortBy], b[_sortBy]) * _sortDesc
            );
          }
          return (b[_sortBy] < a[_sortBy] ? -1 : 1) * _sortDesc;
        }
      });

      return items;
    },
    gotoIncident(incidentId) {
      this.$router.push({
        name: "Incident",
        params: {
          incident_id: incidentId,
        },
      });
    },
    durationInWords,
    titleCase,
  },
};

function formatResponder(responder) {
  if (!responder?.name) return "--";
  if (!responder?.contactMethod?.value) return responder.name;
  return `${responder.name} @ ${formatPhoneNumber(
    responder.contactMethod.value
  )}`;
}
</script>

const conversions = {
  Months: 2592000,
  Weeks: 604800,
  Days: 86400,
  Hours: 3600,
};

export function secondsToUnit(seconds) {
  if (!seconds) return { value: "", unit: "Hours" };

  for (const [unit, factor] of Object.entries(conversions)) {
    if (seconds >= factor) {
      return {
        value: Math.floor(seconds / factor),
        unit,
      };
    }
  }

  return { value: seconds / conversions.Hours, unit: "Hours" };
}

export function convertToSeconds(value, unit) {
  if (!value) return 0;

  return Number(value) * (conversions[unit] || conversions.Hours);
}

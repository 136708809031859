import PageApps from "./PageApps.vue";
import PageSlack from "./PageSlack.vue";
import PageSlackNewConnection from "./PageSlackNewConnection.vue";
import PageSlackConnection from "./PageSlackConnection.vue";

import PageTeams from "./PageTeams.vue";

const routes = {
  navigation: [
    {
      path: "/apps",
      name: "Apps",
      component: PageApps,
    },
    {
      path: "/apps/slack",
      name: "Slack",
      component: PageSlack,
    },
    {
      path: "/apps/slack/slack_new_connection",
      name: "SlackNewConnection",
      component: PageSlackNewConnection,
    },
    {
      path: "/apps/slack/slack_connected",
      name: "SlackConnection",
      component: PageSlackConnection,
    },
    {
      path: "/apps/teams",
      name: "Teams",
      component: PageTeams,
    },
  ],
};

export default routes;

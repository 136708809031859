<template>
  <v-container>
    <v-row class="align-center justify-space-between mb-3">
      <v-col cols="auto" class="d-flex align-center">
        <v-icon class="fab fa-slack" color="#4A154B" style="font-size: 40px" />
        <strong class="text-h5 ml-2">Slack Integration</strong>
      </v-col>
      <v-col cols="auto">
        <span>Connections ({{ connections.length }})</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <strong class="text-h6">Channels Connected</strong>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <strong>Add New Connections</strong>
      </v-col>
    </v-row>

    <v-row class="my-3">
      <v-col cols="4">
        <v-select
          v-model="selectedWorkspace"
          :items="workspaceOptions"
          item-text="name"
          item-value="name"
          label="Workspace"
          outlined
          dense
          @change="handleWorkspaceChange"
        />
      </v-col>

      <v-col cols="4">
        <v-select
          v-model="selectedChannel"
          :items="channelOptions"
          item-text="name"
          item-value="name"
          label="Channel"
          outlined
          dense
          :disabled="!selectedWorkspace"
          @change="handleChannelChange"
        />
      </v-col>

      <v-col cols="4">
        <v-btn
          color="primary"
          :disabled="!selectedWorkspace || !selectedChannel"
          @click="addConnection"
        >
          Add
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="tableHeaders"
          :items="connections"
          item-key="id"
          dense
          outlined
        >
          <template #item.action="{ item }">
            <v-btn text color="error" @click="deleteConnection(item)">
              Delete
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PageSlackConnection",
  data() {
    return {
      selectedWorkspace: null,
      selectedChannel: null,
      workspaceOptions: [
        { name: "Workspace 1" },
        { name: "Workspace 2" },
        { name: "Workspace 3" },
      ],
      channelOptions: [
        { name: "General" },
        { name: "Random" },
        { name: "Announcements" },
      ],
      connections: [
        {
          id: 1,
          workspace: "Workspace 1",
          channel: "General",
          facility: "Facility A",
          lastUpdated: "2024-11-01",
          status: "Active",
        },
        {
          id: 2,
          workspace: "Workspace 2",
          channel: "Announcements",
          facility: "Facility B",
          lastUpdated: "2024-11-02",
          status: "Inactive",
        },
      ],
      tableHeaders: [
        { text: "Workspace", value: "workspace" },
        { text: "Channel", value: "channel" },
        { text: "Facility", value: "facility" },
        { text: "Last Updated By", value: "lastUpdated" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", sortable: false },
      ],
    };
  },
  methods: {
    handleWorkspaceChange() {
      this.selectedChannel = null;
    },
    addConnection() {
      if (this.selectedWorkspace && this.selectedChannel) {
        const newConnection = {
          id: Date.now(),
          workspace: this.selectedWorkspace,
          channel: this.selectedChannel,
          facility: "N/A",
          lastUpdated: new Date().toLocaleDateString(),
          status: "Active",
        };
        this.connections.push(newConnection);
        this.selectedWorkspace = null;
        this.selectedChannel = null;
      }
    },
    deleteConnection(item) {
      this.connections = this.connections.filter(
        (connection) => connection.id !== item.id
      );
    },
  },
};
</script>

<style scoped></style>

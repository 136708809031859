<template>
  <div>
    <DialogViewTask
      v-if="isOpenViewTask"
      v-model="isOpenViewTask"
      :task-id="selectedTaskId"
    />

    <CardLayout v-if="tasks.length > 0" title="Task History">
      <v-row class="cursor-pointer">
        <v-col
          v-for="task in tasks"
          :key="task.id"
          cols="3"
          @click="openTaskDialog(task)"
        >
          <CardLayout :title="task.title">
            <dl class="property-list my-4">
              <dt>Task ID</dt>
              <dd>{{ task.taskId }}</dd>
              <dt>Priority</dt>
              <dd>
                <SeverityChip :severity="titleCase(task.priority)" />
              </dd>
              <dt>Assignee</dt>
              <dd>
                {{ task.assignee.firstName }} {{ task.assignee.lastName }}
              </dd>
              <dt>Due Date</dt>
              <dd>{{ facilityFormatDateTime4Humans(task.dueAt) }}</dd>
            </dl>
          </CardLayout>
        </v-col>
      </v-row>
    </CardLayout>
  </div>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import { ref } from "vue";
import useIncident from "@components/incident/useIncident";
import { formatIncidentType } from "@components/common/helpers/incident";
import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import SeverityChip from "@tod-ui/components/SeverityChip.vue";
import DialogViewTask from "../manage_tasks/DialogViewTask.vue";
import { titleCase } from "@tod-ui/helpers/strings";

export default {
  name: "CardIncidentInfo",
  components: {
    CardLayout,

    CardLayout,
    SeverityChip,
    DialogViewTask,
  },
  props: {
    isAssignToManager: {
      type: Boolean,
      default: false,
    },
    singleColumn: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { incident, loading } = useIncident();
    const isOpenViewTask = ref(false);
    const selectedTaskId = ref({});
    return {
      incident,
      loading,
      isOpenViewTask,
      selectedTaskId,
    };
  },

  data() {
    return {
      tasks: [],
      selectedTask: null,
      isOpen: false,
    };
  },

  watch: {
    incident: {
      immediate: true,
      handler(incident) {
        if (!incident) return;
        this.tasks = incident.tasks || [];
      },
    },
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },

    openTaskDialog(task) {
      this.selectedTaskId = task.id;
      this.isOpenViewTask = true;
    },
    addTask(newTask) {
      this.tasks.push(newTask);
    },
    facilityFormatDateTime4Humans(dateTime) {
      if (!dateTime) return "N/A";
      return new Date(dateTime).toLocaleString();
    },

    formatResponder(responder) {
      if (!responder) return "--";
      if (!responder.contactMethod?.value) return responder.name;
      return `${responder.name} @ ${formatPhoneNumber(
        responder.contactMethod.value
      )}`;
    },
    formatIncidentType,
    titleCase,
  },
};
</script>

<style lang="scss" scoped>
.property-list {
  dd {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &.two-columns {
    grid-template-columns: repeat(2, max-content auto);
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>

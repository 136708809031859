import gql from "graphql-tag";

export const MONITORING_CONTROL_HUB_STATS_ITEM = gql`
  query MonitoringControlHubStatsItem($organizationIds: [ID!]) {
    monitoringControlHubStats(organizationIds: $organizationIds) {
      currentIncidents
      inProgressIncidents
      inProgressOverdueIncidents
      inProgressTasks
      inReviewIncidents
      openIncidents
      openTasks
      overdueIncidents
      overdueTasks
    }
  }
`;

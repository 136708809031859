<template>
  <v-container>
    <v-row class="align-center mb-3">
      <v-col cols="auto">
        <v-icon class="fab fa-slack" color="#4A154B" style="font-size: 40px" />
      </v-col>
      <v-col>
        <strong class="text-h5">Slack Integration</strong>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <strong>Add New Connections</strong>
      </v-col>
    </v-row>

    <v-row class="my-3">
      <v-col cols="4">
        <v-select
          v-model="selectedWorkspace"
          :items="workspaceOptions"
          item-text="name"
          item-value="name"
          label="Workspace"
          outlined
          dense
          @change="handleWorkspaceChange"
        />
      </v-col>

      <v-col cols="4">
        <v-select
          v-model="selectedChannel"
          :items="channelOptions"
          item-text="name"
          item-value="name"
          label="Channel"
          outlined
          dense
          :disabled="!selectedWorkspace"
          @change="handleChannelChange"
        />
      </v-col>

      <v-col cols="4">
        <v-select
          v-model="selectedFacilities"
          :items="facilityOptions"
          item-text="name"
          item-value="name"
          label="Facility Name"
          outlined
          dense
          :disabled="!selectedChannel"
          multiple
          return-object
          @change="handleFacilityChange"
        />
      </v-col>
    </v-row>

    <v-row class="justify-center mt-4">
      <v-col cols="auto">
        <v-btn color="primary" @click="connectProject">Connect Project</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SlackNewConnection",
  data() {
    return {
      selectedWorkspace: null,
      selectedChannel: null,
      selectedFacilities: [],
      workspaceOptions: [
        { name: "Workspace 1" },
        { name: "Workspace 2" },
        { name: "Workspace 3" },
      ],
      channelOptions: [
        { name: "General" },
        { name: "Random" },
        { name: "Announcements" },
      ],
      facilityOptions: [
        { name: "Facility A" },
        { name: "Facility B" },
        { name: "Facility C" },
      ],
    };
  },
  methods: {
    handleWorkspaceChange() {
      this.selectedChannel = null;
      this.selectedFacilities = [];
    },
    handleChannelChange() {
      this.selectedFacilities = [];
    },
    connectProject() {
      this.$router.push({ path: "/apps/slack/slack_connected" });
    },
  },
};
</script>

<style scoped></style>

<template>
  <v-card :id="id" class="w-100 black-border">
    <v-card-text class="py-2 pt-3">
      <v-row dense style="flex-direction: row-reverse">
        <v-col md="3" align="right">
          <div
            :style="{
              backgroundColor: color,
              borderRadius: '50px',
              width: '54px',
              height: '54px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }"
          >
            <v-icon :size="$vuetify.breakpoint.xs ? 30 : 27" color="white">
              {{ icon }}
            </v-icon>
          </div>
        </v-col>
        <v-col md="9" align="left">
          <div class="d-flex align-center pb-1">
            <h4 class="black--text text-md-h4 text-h5 mr-3">
              <template v-if="loading">
                <v-progress-circular indeterminate color="black" />
              </template>
              <template v-else>{{ overdueTasks }}</template>
            </h4>
            <span class="black--text text-subtitle-1">Overdue Tasks</span>
          </div>
          <div class="d-flex align-center mt-2 mb-1">
            <h4 class="black--text text-md-h4 text-h5 mr-3">
              <template v-if="loading">
                <v-progress-circular indeterminate color="black" />
              </template>
              <template v-else>{{ openTasks }}</template>
            </h4>
            <span class="black--text text-subtitle-1">Total Tasks</span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-btn :color="color" block @click="$emit('click', id)">
      <v-row>
        <v-col align="left" md="10">
          <v-toolbar-title alight="left" class="body-2 black--text">
            View Details
          </v-toolbar-title>
        </v-col>
        <v-col md="2">
          <v-icon
            small
            align="right"
            class="icon-details"
            :class="{ open: details }"
            color="grey darken-4"
            >fas fa-arrow-circle-right</v-icon
          >
        </v-col>
      </v-row>
    </v-btn>
  </v-card>
</template>

<script>
import { DASHBOARD_MONITORING_CONTROL_HUB_STATS_ITEM } from "./graphql";
import useFacility from "../authentication/useFacility";
import { ref } from "vue";
export default {
  name: "TaskInfoCard",
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: "#31708f",
    },
    color: {
      type: String,
      default: "#AAAAAA",
    },
    icon: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    details: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { currentFacilityId } = useFacility();

    const overdueTasks = ref(0);
    const openTasks = ref(0);

    return {
      currentFacilityId,
      overdueTasks,
      openTasks,
    };
  },

  apollo: {
    monitoringControlHubStats: {
      fetchPolicy: "no-cache",
      query: DASHBOARD_MONITORING_CONTROL_HUB_STATS_ITEM,
      variables() {
        return {
          organizationIds: [this.currentFacilityId],
        };
      },
      result({ data }) {
        if (data && data.monitoringControlHubStats) {
          this.overdueTasks = data.monitoringControlHubStats.overdueTasks;
          this.openTasks = data.monitoringControlHubStats.openTasks;
        } else {
          console.error("No monitoringControlHubStats data found", data);
        }
      },
      error(error) {
        console.error("Error fetching monitoringControlHubStats:", error);
      },
    },
  },
};
</script>
